


import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom';
import {Label,Input} from "reactstrap";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Button} from 'reactstrap';





const Nextbanner = () => {

  const [badgeBg, setbadgeBg] = useState("");
  const [badgeBg1, setbadgeBg1] = useState("");
  const [badgeBg2, setbadgeBg2] = useState("");
  const [badgeBg3, setbadgeBg3] = useState("");
  const [badgeBg4, setbadgeBg4] = useState("");

  const handleBadgebg = (e) => {
    const input = e.target.value;
    setbadgeBg(input);
  };


  const handleBadgebg1 = (e) => {
    const input = e.target.value;
    setbadgeBg1(input);
  };

  const handleBadgebg2 = (e) => {
    const input = e.target.value;
    setbadgeBg2(input);
  };

  const handleBadgebg3 = (e) => {
    const input = e.target.value;
    setbadgeBg3(input);
  };

  const handleBadgebg4 = (e) => {
    const input = e.target.value;
    setbadgeBg4(input);
  };

document.title ="Event Banner";

  return (
    <React.Fragment>

    
       
         
   
        
        <Container fluid>
          <br/>  <br/> 
          <BreadCrumb title="Event Banner" pageTitle="Forms" />
          <Row>
            <Col lg={12}> 
              <Card>
              <CardHeader >
                
                <div className="d-flex justify-content-between align-items-center">
                 <h2 className="card-title mb-0">Add Event</h2>
                   <Link to="/channellist">
                                      <Button color="primary" className="add-btn me-1">
                                         Event List &nbsp;
                                        <i className="ri-arrow-right-line align-bottom me-1"></i>
                                      </Button>
                                    </Link>
                                  </div>
              </CardHeader>
                <CardBody >
                  <form action="#">

                     
          




                     

       <Row >
          

     


          <Col xl={6}>
                        <div className="mb-3">
  <label htmlFor="event-type" className="form-label">Event Splash Type</label>
  <select
    id="event-type"
    className="form-select"
    style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
    onChange={(event) => {
    }}
  >
    <option value="" >Select Event Type</option>
    <option value="image">Image</option>
    <option value="video">Video</option>
    <option value="splash">Splash</option>
  </select>
</div>



 
                        </Col>
                        <Col xl={6}>
                             <div className="mb-3">
                               <label htmlFor="cleave-date" className="form-label">Splash Loader</label>
                               <input placeholder="Choose Splash Loader" 
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                                 event.target.style.borderColor = 'green';
                               }}
                               className="form-control" type="file" id="formFile"/><br/>
                             </div>
                           </Col> 

          </Row>




   
                
   



   




   
   <br/><br/><br/>
   

                          <div className="live-preview" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <div className="d-flex flex-wrap gap-2">
                                    <Link to="/nextbannerdata">

                                    <Button
                                            color="primary"
                                            className="rounded-pill"
                          
                                          >
                                         Submit
                                          </Button>&nbsp;  
                                          <Button
                                            color="primary"
                                            className="rounded-pill"

                                          >
                                         Add More
                                          </Button>     
                                          </Link>
                          </div> 

                          </div> 
                    </form>
                    
                    <br/><br/>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                 
                        
                        </React.Fragment>
                      );
                    };

                    export default Nextbanner;
