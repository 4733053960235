import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom'; // Make sure to import Navigate from the correct library

function Session({ timeout, onTimeout }) {
  const [redirect, setRedirect] = useState(false); // Initialize redirect state

  useEffect(() => {
    let timer;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        onTimeout();
        setRedirect(true); // Set redirect state to true on timeout
      }, timeout);
    };

    // Attach event listeners to reset the timer on user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);

    // Start the initial timer
    resetTimer();

    return () => {
      // Clean up event listeners when the component unmounts
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      clearTimeout(timer);
    };
  }, [timeout, onTimeout]);

  if (redirect) {
    // Redirect to the login page after timeout
    return <Navigate to="/errorpage" />;
  }

  return null;
}

export default Session;
