
// Add Banner

const Addlive = async (event_url,event_name,event_description,event_date,event_time,event_place,event_schedule_id) => {
    try { 
      const apiKey = process.env.REACT_APP_API_KEY;
      const url = process.env.REACT_APP_API_ENDPOINT+'/add_live';

      const user = window.sessionStorage.getItem("event_key"); 


      const event_key = user; 
  

      const formData = new FormData();
      formData.append('api_key', apiKey);
      formData.append('event_key', event_key);
      formData.append('event_url', event_url);
      formData.append('event_name', event_name);
      formData.append('event_description', event_description);
      formData.append('event_date', event_date);
      formData.append('event_time', event_time);
      formData.append('event_place', event_place);
      formData.append('event_schedule_id', event_schedule_id);      
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      // console.log(data);
    } catch (error) {
    // console.log(error);
    }
  };
  export {Addlive};
  