

// Get Category List

const getcategorylist = async () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const user = window.sessionStorage.getItem("event_key"); 
    const event_key = user; 
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/get_category_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ api_key: apiKey,event_key })
      });

      const data = await response.json();
      return data.catchup_category_list || [];
    } catch (error) {
      return [];
    }
  };  


  const getcategorydata = async (ids) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/get_category_data';
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: ids, api_key: apiKey })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
  
      const data = await response.json();
      const {id, name, sort_order
   } = data;
  
     
  
      return {id, name, sort_order};
    } catch (error) {
      return null;
    }
  };
  

  const updatecategory = async (editData) => {
    try {
      const apiKey = process.env.REACT_APP_API_KEY;
      const url = process.env.REACT_APP_API_ENDPOINT+'/update_category';

      const formData = new FormData();

      formData.append('api_key', apiKey);
      formData.append('id', editData.id);
      formData.append('name', editData.name);
      formData.append('sort_order', editData.sort_order);

      const response = await fetch(url, {
      method: 'POST',
      body: formData
      });
  
      const data = await response.json();
  
  
      if (data.error === true) {
        const error = data.message || 'Update failed';
        return { success: false, error };
      } else {
        return { success: true, message: data.message };
      }
    } catch (error) {
      return { success: false, error: 'Update failed' };
    }
  };
  
// DELETE Category
const deletecatchupcategory = async (CategoryId) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/delete_catchup_category';
    const confirmed = await new Promise((resolve, reject) => {
              $.confirm({
                title: 'Confirm!',
                autoClose: "cancel|10000",
                content: 'Are you sure you want to delete this Event Category!',
                type:"red",
                closeIcon: true,
                buttons: {
                  confirm: {
                    text: 'Confirm', 
                    btnClass: 'btn-red',
                    action: resolve,
                  },
                  cancel: function () {
                  },
                },
              });
            });
    if (confirmed) {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: CategoryId, api_key: apiKey })
    });

    const data = await response.json();

    if (data.error) {
      const error = data.message || 'Delete failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  }} catch (error) {
    return { success: false, error: 'Delete failed' };
  }
};


export {getcategorylist,deletecatchupcategory,getcategorydata,updatecategory};
  