
import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown,Container,Row,Col,Card,CardHeader,Button,CardBody,ModalBody,Modal,Label,ModalHeader,ModalFooter} from 'reactstrap';
import { Link,useNavigate } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Switch from '@mui/material/Switch';
import logoLight from "../../assets/images/avatar-8.jpg";
import {getbannerlist,deletebanner,getbannerdata,updatebanner} from '../../Apicalls/EventBanner/bannerlist';
import {addBanner} from '../../Apicalls/EventBanner/Addbanner';
import Session from "../../sessionmaindata"
import Banner from './Banner.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bannerlist = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
      setmodal_list(!modal_list);
  };
    const [searchTerm, setSearchTerm] = useState('');
    const [Eventlist, setEventlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [editPackage, setEditPackage] = useState(null);


    const firstEventKey = Eventlist.length > 0 ? Eventlist[0].event_key : '';
    
    const [event_key, setevent_key] = useState(firstEventKey); // Set initial state to firstEventKey

    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const [banner_title, setbanner_title] = useState("");
    const [banner_description, setbanner_description] = useState("");
    const [banner_type, setbanner_type] = useState("");
    const [bannerimage, setbannerimage] = useState("");


    
const [errorbannertitle, seterrorbanner_title] = useState("");
const [errorEvent_key, setErrorevent_key] = useState([]);
const [errorbannerdescription, seterrorbanner_description] = useState("");
const [errorbannertype, seterrorbanner_type] = useState("");
const [errorbannerimage, seterrorbannerimage] = useState("");

    const navigate = useNavigate();

 
    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await getbannerlist();
        setEventlist(data);
        // setFilteredData(data);
        setIsLoading(false);
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      if (Eventlist.length > 0) {
        setevent_key(Eventlist[0].event_key); // Set event_key to the value of the first event key
      }
    }, [Eventlist]);


    async function handleDelete(BannerId) {
      const data = await deletebanner(BannerId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await getbannerlist();
        setEventlist(updatedData);
      }
    }



    const Addbanner = async () => {
      if (isLoading) {
        return;
      }
      if (!event_key || !banner_title  || !banner_description || !banner_type) {
      setErrorevent_key(event_key ? "" : "Enter The Event KEY");
      seterrorbanner_title(banner_title ? "" : "Enter The Banner Title");
      seterrorbanner_description(banner_description ? "" : "Enter The Banner Description");
      seterrorbanner_type(banner_type ? "" : "Enter The banner Type");
      seterrorbannerimage(bannerimage ? "" : "Choose banner Image");
  
      return;
    } 
      try {    
        setIsLoading(true); 
        await addBanner(event_key,banner_title,banner_description,banner_type);
        window.location.href = `/bannerlistdatas`;

      } catch (error) {
        // setError(error.message);
      } finally {
        setIsLoading(false); // Reset loading to false after API call (success or error)
      } 
    }

    async function handleUpdate() { 
      // Get the updated data from your form inputs

          const updatedData = {
            id : editPackage.id,
            banner_title : editPackage.banner_title,
            banner_description :editPackage.banner_description,
            banner_type :editPackage.banner_type,
      };
       const data = await updatebanner(updatedData);
  
      if (data.error == true) {
        const error = data.message || 'Update failed';   
        $.alert({
          title: 'Error',
          content: error,
        }); // Fetch updated country list after successful update
      } else {
        handlePopupClose();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          const updatedData = await getbannerlist();
          setEventlist(updatedData);
      }
    }





    async function handleEdit(ids) {
      const data = await getbannerdata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
      }
    }

    const handlebannerstatus = useCallback(async (id) => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  

        const response = await fetch(apiUrl +'/change_event_banner_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ api_key: apiKey, id: id }),
        });
        const data = await response.json();
      } catch (error) {
        // console.log(error);
      }
      const updatedData = await getbannerlist();
      setEventlist(updatedData);
    }, []);



    const toggle = () => {
        setIsEdit(!isEdit);
      };

      const handleClose = () => {
        toggle(); // Close the modal by toggling the state
      };


      const handledescription = (e) => {
        const input = e.target.value;
        if (input.length <= 75) {
          setbanner_description(input);
          seterrorbanner_description(''); // Clear any previous error message
        } else {
          seterrorbanner_description('Event Banner Description should be maximum 75 characters.'); // Set error message
        }
      };
      
      const handlebannertitle = (e) => {
        const input = e.target.value;
        if (input.length <= 25) {
          setbanner_title(input);
          seterrorbanner_title(''); // Clear any previous error message
        } else {
          seterrorbanner_title('Event Banner Title should be maximum 25 characters.'); // Set error message
        }
      };



    
      const handlebannertype = (e) => {
        const input = e.target.value;
        setbanner_type(input);
      };
    

      function handlePopupClose() {
        setmodal_list(false);
      }



      const handleeventkey = (e) => {
        const input = e.target.value;
        if (input.length <= 8) {
          setevent_key(input);
          // setErrorevent_key(''); // Clear any previous error message
        } else {
          // setErrorevent_key('Event key should be maximum 8 characters.'); // Set error message
        }
      };
      


    const columns = [
      
        {
            name: <span className='font-weight-bold fs-13'>Banner Image</span>,
            sortable: true,
            selector: (cell) => {
                return (
                    <React.Fragment>
                        <div className="avatar-group">
                                <Link  to="#" className="avatar-group-item">
                                    <img src={cell.banner_url} alt="" className="rounded-circle avatar-sm" />
                                </Link>
                   
                        </div>
                    </React.Fragment>
                );
            },
        },
        {
          name: <span className='font-weight-bold fs-13'>Banner Title</span>,
          selector: row => row.banner_title,
          sortable: true
      },
        {
            name: <span className='font-weight-bold fs-13'>Banner Type</span>,
            selector: row => row.banner_type,
            sortable: true
        },
      
        {
          name: 'Status',
          cell: (row) => (
            <Switch
            checked={row.status.toLowerCase() === 'enable'}
            onChange={() => handlebannerstatus(row.id)}
            />
          ),
          selector: (row) => row.status,
          sortable: true,
        },
      
         
        {
            name: 'Action',
            cell: (row) => (
                <div>
      

                <li className="list-inline-item edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => {
                          handleEdit(row.id);
                          // console.log(row.id)
                          tog_list();
                        }}
                      >
                        <i className="ri-edit-2-fill fs-16"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleDelete(row.id)}
                      >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        
                      </Link>
                    </li>
      
      
              </div>

            ),
            selector: (row) => row.Status,
            sortable: true,
          },
        
    ];

    
    const filteredData = Eventlist.filter((event) =>
    Object.values(event).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };

  
    return (
      <React.Fragment>
<Session timeout={1440000} onTimeout={handleTimeout} />

        <Container fluid>  <br/>  <br/> 
        <BreadCrumb  pageTitle="" />
     
        <Row>
            <Col lg={12}>
                <Card>
              

                    <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0"><span>BannerList - </span>{firstEventKey}</h4>

  
</div>

                      </CardHeader>

<ToastContainer/>

                   
                      
                      
                            
                    <CardBody>
                    <Row className="g-4 mb-3">
                        <Col className="col-sm">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <i className="ri-search-line search-icon"></i>
                          
                            </div>
                 
                          </div>

                        </Col>


                        
                        <Col className="col-sm">

                        <div className="d-flex justify-content-between align-items-center">
  <h4 className="card-title mb-0"></h4>
  <div className="d-flex align-items-center">
     
  <div>
      <Button color="primary" onClick={toggle}>
        Add Banner
      </Button>

      <Modal
        isOpen={isEdit}
        toggle={toggle}
        centered
        size="xl"
        className="border-0"
        modalClassName="modal fade zoomIn"
      >
            <ModalHeader className="p-3 bg-soft-info" toggle={toggle} >
          {!!isEdit ? "Add Banner" : "update"}
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row className="g-3">
            <Col xxl={12}>
            <Row >
            <Col xl={6}>
  <div className="mb-3">
    <label htmlFor="cleave-date" className="form-label">Event Key</label>
    <input
      placeholder="Enter Event Key"
      // minLength={5}
      // maxLength={20}
      value={event_key}
      onChange={handleeventkey}
      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
      className="form-control"
      disabled
    />
    <br />
  </div>
  {errorEvent_key && <p className="text-danger">{errorEvent_key}</p>}
</Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Banner Title</label>
                            <input placeholder="Enter Banner Title" 
                            // minLength={5}
                            // maxLength={20}
                            value={banner_title}
                            onChange={handlebannertitle}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorbannertitle && <p className="text-danger">{errorbannertitle}</p>}

                        </Col>

    
                        <Col xl={6}>
                        <div className="mb-3">
                          <label htmlFor="cleave-date" className="form-label">Banner Description</label>
                          <textarea
                            placeholder="Enter Banner Description"
                            // minLength={5}
                            // maxLength={20}
                            value={banner_description}
                            onChange={handledescription}
                            className="form-control"
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                          /><br />

                        </div>
                        {errorbannerdescription && <p className="text-danger">{errorbannerdescription}</p>}

                        </Col>
                        </Row>

                        <Row >
                      

                        <Col xl={6}>
                        <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Banner Type</label>
                      <select
                        id="event-type"
                        className="form-select"
                        value={banner_type}
                        onChange={handlebannertype}
                        style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                  
                      >
                        <option >Select Banner Type</option>
                        <option value="LANDSCAPE">LANDSCAPE</option>
                        <option value="PORTRAIT">PORTRAIT</option>
                      </select>
                    </div>

                    {errorbannertype && <p className="text-danger">{errorbannertype}</p>}

                  </Col>
                  <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Banner Image</label>
                                        <input type="file" className="form-control"
                          id="bannerimg" accept="image/*"
                          // value={Bannerimage}
                          // onChange={handlebannerimage}
                          style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                          onInput={(event) => {
                            event.target.style.borderColor = 'green';
                          }}
                        
                           /><br/>
                          </div>
                          {errorbannerimage && <p className="text-danger">{errorbannerimage}</p>}

                        </Col>
                 </Row>

                 

                      


            </Col>
          </Row>
        </ModalBody>
        <div   style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Button
              type="submit"
              className="btn btn-success rounded-pill"              
              id="add-btn"
              style={{width:"100px"}}
              onClick={Addbanner}
            >
Submit      </Button></div>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn-light" onClick={handleClose}>
              Close
            </Button>
            {/* <button
              type="submit"
              className="btn btn-success"
              id="add-btn"
            //   onClick={handleFormSubmit}
            >
Add More            </button> */}
          </div>
        </div>
      </Modal>
    </div>
    
    &nbsp;&nbsp;
    <Link to="/Eventlistdatas">
      <Button color="primary">Event List &nbsp;</Button>
    </Link>
  </div>
</div>
</Col>

                      </Row>

     

{isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    highlightOnHover={true} 
                                    
                                />
                                  )}


            
                         </CardBody>
                  
                </Card>
            </Col>
        </Row>

    </Container>

{editPackage && (
  <Modal className="modal-xl zoom" isOpen={modal_list} toggle={tog_list} centered>
    <ModalHeader className="bg-light p-3" toggle={tog_list}>
      Update Banner List
    </ModalHeader>
    <form className="tablelist-form">
      <ModalBody>

           <Row>
               <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                    Banner Title
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.banner_title} 
                        onChange={e => setEditPackage({ ...editPackage, banner_title: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>   

               <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                    Banner Description
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.banner_description} 
                        onChange={e => setEditPackage({ ...editPackage, banner_description: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>   

        

               <Col xl={6}>
                        <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Banner Type</label>
                      <select
                        id="event-type"
                        className="form-select"
                        value={editPackage.banner_type} 
                        onChange={e => setEditPackage({ ...editPackage, banner_type: e.target.value })}
                        style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                  
                      >
                       <option value={editPackage.banner_type}>
                          Selected Type -{editPackage.banner_type}
                        </option>
                        <option value="LANDSCAPE">LANDSCAPE</option>
                        <option value="PORTRAIT">PORTRAIT</option>
                      </select>
                    </div>

 
                  </Col>

                  </Row>
                  <Row>
                        <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Banner Image</label>
                                          <br/>
                                          <img src={editPackage.banner_url} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="bannerimg" accept="image/*" />

                           </Col> 


                           </Row>

              


      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <button type="button" className="btn btn-light" onClick={handlePopupClose}>
            Close
          </button>
          <Button color="primary" 
         onClick={handleUpdate}
           >
            Update
          </Button>
        </div>
      </ModalFooter>
    </form>
  </Modal>
)}







    </React.Fragment>
      
    );
};



export default Bannerlist;


