
// Add Banner


  const addBanner = async (event_key,banner_title,banner_description,banner_type) => {
    try { 
      const apiKey = process.env.REACT_APP_API_KEY;
      const url = process.env.REACT_APP_API_ENDPOINT+'/add_banner';
      //       const user = window.localStorage.getItem("alleventkey"); 
      //       const event_key = user; 
      const bannerimg = document.getElementById('bannerimg');
      const Bannerimage = bannerimg.files[0];
      const formData = new FormData();
      formData.append('api_key', apiKey);
      formData.append('event_key', event_key);
      formData.append('banner_title', banner_title);
      formData.append('banner_description', banner_description);
      formData.append('banner_type', banner_type);
      formData.append('banner_url', Bannerimage);
   
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      // console.log(data);
    } catch (error) {
    // console.log(error);
    }
  };

  export {addBanner};
  