import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row,  Form,  Nav,NavItem,NavLink,TabContent,TabPane} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom';
import {Label,Input} from "reactstrap";
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Button} from 'reactstrap';
import classnames from "classnames";
import Banner from './Banner.css';
import {Addevent} from '../../Apicalls/Event/Addevent';
import {addBanner} from '../../Apicalls/EventBanner/Addbanner';
import {geteventlist} from '../../Apicalls/Event/Eventlist';

import Session from "../../sessionmaindata"



const AddEvent = () => {

 
  const [inputCount, setInputCount] = useState(0);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [eventdata, seteventdata] = useState([]);


  const [event_key, setevent_key] = useState("");
  const [event_url, setevent_url] = useState("");
  const [event_name, setevent_name] = useState("");
  const [event_sub_title, setevent_sub_title] = useState("");
  const [event_type, setevent_type] = useState("");
  const [event_date, setevent_date] = useState("");
  const [event_location, setevent_location] = useState("");
  const [event_nature, setevent_nature] = useState("");
  const [event_splash_type, setevent_splash_type] = useState("");
  const [badge_text, setbadge_text] = useState("");
  const [badge_color, setbadge_color] = useState("");
  const [event_color_code, setevent_color_code] = useState("");
  const [event_appbar_color_code, setevent_appbar_color_code] = useState("");
  const [event_bottombar_color_code, setevent_bottombar_color_code] = useState("");
  const [event_background_color_code, setevent_background_color_code] = useState("");
  const [event_schedule, setevent_schedule] = useState("");
  const [banner_title, setbanner_title] = useState("");
  const [banner_description, setbanner_description] = useState("");
  const [banner_type, setbanner_type] = useState("");
  const [eventimage, setevent_image] = useState("");
  const [eventqrcode, seteventqr] = useState("");
  const [eventsplashloader, setsplashloader] = useState('');
  const [eventsplashlandscape, setsplashlandscape] = useState('');
  const [eventsplashurlportrait, setsplashportrait] = useState('');
  const [bannerimage, setbannerimage] = useState("");
  // const [eventSplashType, setEventSplashType] = useState("");
  const navigate = useNavigate();



// ERROr STATE 
const [errorEvent_key, setErrorevent_key] = useState([]);
const [errorEvent_url, seterrorEvent_url] = useState([]);
const [errorevent_name, seterrorevent_name] = useState([]);
const [errorevent_sub_title, seterrorevent_sub_title] = useState([]);
const [errorevent_type, seterrorevent_type] = useState([]);
const [errorevent_date, seterrorevent_date] = useState([]);
const [errorevent_location, seterrorevent_location] = useState([]);
const [errorevent_nature, seterrorevent_nature] = useState([]);
const [errorevent_splash_type, seterrorevent_splash_type] = useState([]);
const [errorbadge_text, seterrorbadge_text] = useState([]);
const [errorbadge_color, seterrorbadge_color] = useState([]);
const [errorevent_color_code, seterrorevent_color_code] = useState([]);
const [errorevent_appbar_color_code, seterrorevent_appbar_color_code] = useState([]);
const [errorevent_bottombar_color_code, seterrorevent_bottombar_color_code] = useState([]);
const [errorevent_background_color_code, seterrorevent_background_color_code] = useState([]);
const [errorevent_schedule, seterrorevent_schedule] = useState([]);
const [erroreventimage, seterroreventimage] = useState("");
const [erroreventsplashloader, seterroreventsplashloader] = useState("");
const [erroreventsplashurlportrait, seterroreventsplashurlportrait] = useState("");
const [erroreventqrcode, seterroreventqrcode] = useState("");



const [errorbannertitle, seterrorbanner_title] = useState("");
const [errorbannerdescription, seterrorbanner_description] = useState("");
const [errorbannertype, seterrorbanner_type] = useState("");
const [errorbannerimage, seterrorbannerimage] = useState("");






const Addpublicevents = async (event_key, event_url, event_name, event_sub_title, event_type, event_date, event_location, event_nature, event_splash_type, badge_text, badge_color, event_color_code, event_appbar_color_code, event_bottombar_color_code, event_background_color_code, event_schedule, eventimage, eventqrcode, eventsplashloader, eventsplashurlportrait, eventsplashlandscape) => {
 
  try {
     const apiKey = process.env.REACT_APP_API_KEY;
     const url = process.env.REACT_APP_API_ENDPOINT + '/add_event';
 
     const formData = new FormData();
     formData.append('api_key', apiKey);
     formData.append('event_key', event_key);
     formData.append('event_url', event_url);
     formData.append('event_name', event_name);
     formData.append('event_sub_title', event_sub_title);
     formData.append('event_type', event_type);
     formData.append('event_date', event_date);
     formData.append('event_location', event_location);
     formData.append('event_nature', event_nature);
     formData.append('event_splash_type', event_splash_type);
     formData.append('badge_text', badge_text);
     formData.append('badge_color', badge_color);
     formData.append('event_color_code', event_color_code);
     formData.append('event_appbar_color_code', event_appbar_color_code);
     formData.append('event_bottombar_color_code', event_bottombar_color_code);
     formData.append('event_background_color_code', event_background_color_code);
     formData.append('event_schedule', event_schedule);
     formData.append('event_image', eventimage);
     formData.append('event_qr_code', eventqrcode);
     formData.append('event_splash_loader', eventsplashloader);
     formData.append('event_splash_url_portrait', eventsplashurlportrait);
     formData.append('event_splash_url_landscape', eventsplashlandscape);
     const response = await fetch(url, {
       method: 'POST',
       body: formData,
     });
     const data = await response.json();
     if (data.valid_event === 'NO') {
       // Handle the case where the event key already exists
       setErrorevent_key(data.message);
     } else {
       console.log(data); // or any other handling for successful response
       toggleArrowTab(activeArrowTab + 1);
     }
   } catch (error) {
     console.log(error);
   }
 };
 
 
  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    
    // Check for empty input fields
    if (!event_key || !event_url || !event_name || !event_sub_title || !event_type || !event_date || !event_location || !event_nature || !event_splash_type
      || !badge_text || !badge_color || !event_color_code || !event_appbar_color_code || !event_bottombar_color_code || !event_background_color_code || !event_schedule
      || !eventimage || !eventqrcode || !eventsplashloader || !eventsplashurlportrait || !eventsplashlandscape) {
      
      // Update error messages
      setErrorevent_key(event_key ? "" : "Enter The Event KEY");
      seterrorEvent_url(event_url ? "" : "Enter The Event Url");
      seterrorevent_name(event_name ? "" : "Enter The Event Name");
      seterrorevent_sub_title(event_sub_title ? "" : "Enter The Event Subtitle");
      seterrorevent_type(event_type ? "" : "Enter The Event Type");
      seterrorevent_date(event_date ? "" : "Enter The Event Date");
      seterrorevent_location(event_location ? "" : "Enter The Event Location");
      seterrorevent_nature(event_nature ? "" : "Enter The Event Nature");
      seterrorevent_splash_type(event_splash_type ? "" : "Enter The Event Splash Type");
      seterrorbadge_text(badge_text ? "" : "Enter The Badge Text");
      seterrorbadge_color(badge_color ? "" : "Enter The Badge Color");
      seterrorevent_color_code(event_color_code ? "" : "Enter The Event Color Code");
      seterrorevent_appbar_color_code(event_appbar_color_code ? "" : "Enter The Appbar Color Code");
      seterrorevent_bottombar_color_code(event_bottombar_color_code ? "" : "Enter The BottomBar Color Code");
      seterrorevent_background_color_code(event_background_color_code ? "" : "Enter The background color code");
      seterrorevent_schedule(event_schedule ? "" : "Enter The Event Schedule");
      
      return;
    } 
  
    // Call the function to add public events
    await Addpublicevents(event_key, event_url, event_name, event_sub_title, event_type, event_date, event_location, event_nature, event_splash_type, badge_text, badge_color, event_color_code, event_appbar_color_code, event_bottombar_color_code, event_background_color_code, event_schedule, eventimage, eventqrcode, eventsplashloader, eventsplashurlportrait, eventsplashlandscape);

  };






  
  const Addbanner = async () => {
    if (isLoading) {
      return;
    }
    if (!event_key || !banner_title  || !banner_description || !banner_type) {
    setErrorevent_key(event_key ? "" : "Enter The Event KEY");
    seterrorbanner_title(banner_title ? "" : "Enter The Banner Title");
    seterrorbanner_description(banner_description ? "" : "Enter The Banner Description");
    seterrorbanner_type(banner_type ? "" : "Enter The banner Type");
    seterrorbannerimage(bannerimage ? "" : "Choose banner Image");

    return;
  } 
    try {    
      setIsLoading(true); 
      await addBanner(event_key,banner_title,banner_description,banner_type,bannerimage);
      navigate('/Eventlistdatas');
    } catch (error) {
      // setError(error.message);
    }finally {
      setIsLoading(false); // Reset loading to false after API call (success or error)
    }
    
  }


  useEffect(() => {
    const fetchData = async () => {
      // Simulating an API call or any asynchronous operation
      await new Promise((resolve) => setTimeout(resolve));
      const data = await  geteventlist();
      seteventdata(data);
    };

    fetchData();
  }, []);

  const handleeventkey = (e) => {
    const input = e.target.value;
    if (input.length <= 8) {
      setevent_key(input);
      setErrorevent_key(''); // Clear any previous error message
    } else {
      setErrorevent_key('Event key should be maximum 8 characters.'); // Set error message
    }
  };
  


  // const handleeventurl = (e) => {
  //   const input = e.target.value;
  //   setevent_url  (input);
  // };


  const handleeventurl = (e) => {
    const input = e.target.value;
    setevent_url(input);
  
    // Regular expression to check if the input is a valid URL starting with http:// or https://
    const urlRegex = /^(https?:\/\/)/;
  
    if (!urlRegex.test(input)) {
        seterrorEvent_url('Please enter a valid Link starting with "http://" or "https://".');
    } else {
        seterrorEvent_url('');
    }
};


  // const handleeventname = (e) => {
  //   const input = e.target.value;
  //   setevent_name  (input);
  // };


const handleeventname = (e) => {
const input = e.target.value;
const maxLength = 25;
setevent_name(input);
if (input.length <= maxLength) {
seterrorevent_name('Event Name must be between 3 and 25 characters.');
}else{
setevent_name(input);
seterrorevent_name('');
}
};

const maxLength = 20; // Define your maxLength here
const charactersLeft = maxLength - event_name.length;




  // const handleeventsubtitle = (e) => {
 //   const input = e.target.value;
  //   setevent_sub_title  (input);
  // };


  const handleeventsubtitle = (e) => {
    const input = e.target.value;
    if (input.length <= 35) {
      setevent_sub_title(input);
      seterrorevent_sub_title(''); // Clear any previous error message
    } else {
      seterrorevent_sub_title('Event Subtitle should be maximum 35 characters.'); // Set error message
    }
  };

 


  
  const handleeventtype = (e) => {
    const input = e.target.value;
    setevent_type  (input);
  };

  // const handleSplashTypeChange = (event) => {
  //   setevent_splash_type(event.target.value);
  // };

  const handleSplashTypeChange = (e) => {
    const selectedEventType = e.target.value;
    setevent_splash_type(selectedEventType);

    // If eventTypechange is marriage, corporate, or party, set event_title1 to "#"
    if (selectedEventType === 'none' || selectedEventType === 'image' || selectedEventType === 'video') {
      setsplashloader('#');
      setsplashlandscape('#');
      setsplashportrait('#');
     

    } else {
        // Otherwise, reset event_title1
        // Reset other states as needed
    }
};


  const handleeventdate = (e) => {
    const input = e.target.value;
    setevent_date  (input);
  };

  const handleeventlocation = (e) => {
    const input = e.target.value;
    setevent_location  (input);
  };


  
  const handleeventnature = (e) => {
    const input = e.target.value;
    setevent_nature  (input);
  };

 
  // const handlesplashtype = (e) => {
  //   const input = e.target.value;
  //   setevent_splash_type  (input);
  // };


  const handlebadgetext = (e) => {
    const input = e.target.value;
    setbadge_text  (input);
  };

  const handlebadgecolor = (e) => {
    const input = e.target.value;
    setbadge_color  (input);
  };


  const handleeventcolorcode = (e) => {
    const input = e.target.value;
    setevent_color_code  (input);
  };

  const handleappbarcolorcode = (e) => {
    const input = e.target.value;
    setevent_appbar_color_code  (input);
  };


  const handlebottomcolorcode = (e) => {
    const input = e.target.value;
    setevent_bottombar_color_code  (input);
  };

  const handlebackgroundcolor = (e) => {
    const input = e.target.value;
    setevent_background_color_code  (input);
  };

  const handleeventschedule = (e) => {
    const input = e.target.value;
    setevent_schedule  (input);
  };


  const handledescription = (e) => {
    const input = e.target.value;
    if (input.length <= 75) {
      setbanner_description(input);
      seterrorbanner_description(''); // Clear any previous error message
    } else {
      seterrorbanner_description('Event Banner Description should be maximum 75 characters.'); // Set error message
    }
  };
  // const handlebannertitle = (e) => {
  //   const input = e.target.value;
  //   setbanner_title(input);
  // };

  const handlebannertitle = (e) => {
    const input = e.target.value;
    if (input.length <= 25) {
      setbanner_title(input);
      seterrorbanner_title(''); // Clear any previous error message
    } else {
      seterrorbanner_title('Event Banner Title should be maximum 25 characters.'); // Set error message
    }
  };


  const handlebannertype = (e) => {
    const input = e.target.value;
    setbanner_type(input);
  };

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }
  const handleAddMoreClick = () => {
    setInputCount(inputCount + 1);
  };

  const handleeventimage = (event) => {
    const file = event.target.files[0];
    setevent_image(file);
  };

  const handleqr = (event) => {
    const file = event.target.files[0];
    seteventqr(file);
  };

  const handlesplashloader = (event) => {
    const file = event.target.files[0];
    setsplashloader(file);
  };

  
  const handleportraitimage = (event) => {
    const file = event.target.files[0];
    setsplashportrait(file);
  };

  const handlesplashpotrait = (event) => {
    const file = event.target.files[0];
    setsplashportrait(file);
  };


  const handleportraitimage1 = (event) => {
    const input = event.target.value;
    setsplashportrait(input);
  };
  const handlelandscape1 = (event) => {
    const input = event.target.value;
    setsplashlandscape(input);
  };
  const handlelandscape = (event) => {
    const file = event.target.files[0];
    setsplashlandscape(file);
  };
  const handlesplashlandscape = (event) => {
    const file = event.target.files[0];
    setsplashlandscape(file);
  };

  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };



  return (




    
<React.Fragment>
<Session timeout={1440000} onTimeout={handleTimeout} />
{isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <Container fluid>
                                    <br/>  <br/> 
                                    <BreadCrumb title="" />
                                    <Row>
                          
                          
                                    <Col lg={12}> 
                                        <Card>
                                        <CardHeader >
                                          
                                          <div className="d-flex justify-content-between align-items-center">
                                           <h2 className="card-title mb-0">Add Event</h2>
                                             <Link to="/Eventlistdatas">
                                                                <Button color="primary" className="add-btn me-1">
                                                                   Event List &nbsp;
                                                                  <i className="ri-arrow-right-line align-bottom me-1"></i>
                                                                </Button>
                                                              </Link>
                                                            </div>
                                        </CardHeader>
                                          <CardBody>
                                            <Form className="form-steps"> 
                                              <div className="text-center pt-3 pb-4 mb-1">
                                                {/* <img src={logoDark} alt="" height="17" /> */}
                                              </div>
                                              <div className="step-arrow-nav mb-4">
                                                <Nav
                                                  className="nav-pills custom-nav nav-justified"
                                                  role="tablist"
                                                >
                                                  <NavItem>
                                                    <NavLink
                                                    href="#"
                                                      id="steparrow-gen-info-tab"
                                                      className={classnames({
                                                        active: activeArrowTab === 4,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 3,
                                                      })}
                                                      onClick={() => {
                                                        toggleArrowTab(4);
                                                      }}
                                                    >
                                                      Add Event
                                                    </NavLink>
                                                  </NavItem>
                                                  <NavItem>
                                                    <NavLink
                                                    href="#"
                                                      id="steparrow-gen-info-tab"
                                                      className={classnames({
                                                        active: activeArrowTab === 5,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 4,
                                                      })}
                                                      onClick={() => {
                                                        toggleArrowTab(5);
                                                      }}
                                                    >
                                                      Add Event Banner
                                                    </NavLink>
                                                  </NavItem>
                                                  {/* <NavItem>
                                                    <NavLink
                                                    href="#"
                                                      id="steparrow-gen-info-tab"
                                                      className={classnames({
                                                        active: activeArrowTab === 6,
                                                        done: activeArrowTab <= 6 && activeArrowTab > 5,
                                                      })}
                                                      onClick={() => {
                                                        toggleArrowTab(6);
                                                      }}
                                                    >
                                                      Finish
                                                    </NavLink>
                                                  </NavItem> */}
                                                </Nav>
                                              </div>
                                              <TabContent activeTab={activeArrowTab}>
                                                <TabPane id="steparrow-gen-info" tabId={4}>
                                                  <div>
                                                  <Row >
                                                  <Col xl={6}>
                                                <div className="mb-3">
                                                  <label htmlFor="cleave-date" className="form-label">Event Key</label>
                                                  <input
                                                    placeholder="Enter Event Key"
                                                    // minLength={5}
                                                    // maxLength={20}
                                                    value={event_key}
                                                    onChange={handleeventkey}
                                                    style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                    className="form-control"
                                                  />
                                                  <br />
                                                </div>
                                                {errorEvent_key && <p className="text-danger">{errorEvent_key}</p>}
                                              </Col>
                          
                                              
                                              </Row>
                          
                          
                          <Row >
                                                    
                                                  <Col xl={6}>
                                                  <div className="mb-3">
                                                    <label htmlFor="cleave-date" className="form-label">Event Title</label>
                                                    <input
                                                      placeholder="Enter Event Title"
                                                      minLength={5}
                                                      maxLength={25}
                                                      value={event_name}
                                                      onChange={handleeventname}
                                                      className="form-control"
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                    /><br />
                          
                          
                                                  </div>
                                                  {errorevent_name && <p className="text-danger">{errorevent_name}</p>}
                          
                                                  </Col>
                          
                                                  <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="cleave-date" className="form-label">Event Subtitle</label>
                                                      <input placeholder="Enter Event Subtitle" 
                                                      // minLength={3}
                                                      // maxLength={34}
                                                      value={event_sub_title}
                                                      onChange={handleeventsubtitle}
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                      className="form-control"/><br/>
                                                    </div>
                                                    {errorevent_sub_title && <p className="text-danger">{errorevent_sub_title}</p>}
                          
                                                  </Col>
                          
                                                  </Row>
                          
                          
                                                  <Row >
                          
                          
                              
                                                  <Col lg={6} >
                                                                      <label htmlFor="cleave-date-format" className="form-label">Event Nature</label>
                                                                          <div 
                                                                             value={event_nature}
                                                                             onChange={handleeventnature}  
                                                                          >
                                                                             
                                                                             <input type="radio" id="html" name="Premium1" value="public" />&nbsp;&nbsp;
                                                                              <label htmlFor="private">Public</label><br/>
                                                                              <input type="radio" id="css" name="Premium1" value="private" /> &nbsp;
                                                                              <label htmlFor="public">Private</label><br/>
                                                                             
                                                                          </div>
                                                                          {errorevent_nature && <p className="text-danger">{errorevent_nature}</p>}
                          
                                                                      </Col>
                          
                          
                                                  <Col xl={6}>
                                                  <div className="mb-3">
                            <label htmlFor="event-type" className="form-label">Event Type</label>
                            <select
                              id="event-type"
                              className="form-select"
                              style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                           
                              value={event_type}
                              onChange={handleeventtype}
                              
                            >
                              <option value >Select Event Type</option>
                              <option value="sports">Sports</option>
                              <option value="marriage">Marriage</option>
                              <option value="corporate">Corporate Event</option>
                              <option value="party">Party Event</option>
                          
                            </select>
                          </div>
                          {errorevent_type && <p className="text-danger">{errorevent_type}</p>}
                          
                          
                          
                           
                                                  </Col>
                                            
                          
                                                  </Row>
                          
                          
                          
                          
                                                  <Row >
                                    
                          
                                                  <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="cleave-date" className="form-label">Event Date</label>
                                                      <input  type="date" className="form-control" id="exampleInputdate" 
                                                      // minLength={5}
                                                      // maxLength={20}
                                                      value={event_date}
                                                      onChange={handleeventdate}
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                      /><br/>
                                                    </div>
                                                    {errorevent_date && <p className="text-danger">{errorevent_date}</p>}
                          
                                                  </Col>
                          
                          
                                                  <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="cleave-date" className="form-label">Event Location</label>
                                                      <input placeholder="Enter Event Location" 
                                                      // minLength={5}
                                                      // maxLength={20}
                                                      value={event_location}
                                                      onChange={handleeventlocation}
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                      className="form-control"/><br/>
                                                    </div>
                                                    {errorevent_location && <p className="text-danger">{errorevent_location}</p>}
                          
                                                  </Col>
                          
                          
                          
                          
                                                  </Row>
                          
                          
                          
                                 <Row >
                          
                                 <Col lg={6} >
                          
                          <label htmlFor="cleave-date-format" className="form-label">Event Schedule</label>
                                      <div 
                                       value={event_schedule}
                                       onChange={handleeventschedule}>
                                       <input type="radio" id="html" name="Catch1" value="yes" />&nbsp;&nbsp;
                                        <label htmlFor="No">Yes</label><br/>
                                        <input type="radio" id="css" name="Catch1" value="No" /> &nbsp;
                                        <label htmlFor="No">No</label><br/>
                                      
                                      
                                    </div>
                                    {errorevent_schedule && <p className="text-danger">{errorevent_schedule}</p>}
                          
                          </Col>
                                 <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="handleeventimage" className="form-label">Event Image</label>
                                          
                                                      
                                                         
                                                            <input
                                                              type="file"
                                                              className="form-control"
                                                              id="handleeventimage"
                                                              accept="image/*"
                                                              onChange={handleeventimage}
                                                            /> 
                                                      
                                                      <br/>
                                                    </div>
                                                     {erroreventimage && <p className="text-danger">{erroreventimage}</p>}
                          
                                                  </Col>
                             
                          
                          
                          
                          
                          
                                                  </Row>
                          
                          
                          
                          
                           
                             
                          
                          
                                                <Row>   
                                                    <Col xl={6}>
                                                       <div className="mb-3">
                                                         <label htmlFor="cleave-date" className="form-label">Event Qr Code</label>
                                                 
                                                         
                                                         
                                                         <input
                                                              type="file"
                                                              className="form-control"
                                                              id="handleqrcode"
                                                              accept="image/*"
                                                              onChange={handleqr}
                                                            /> 
                                                         
                                                         
                                                         <br/>
                                                       </div>
                                                       {erroreventqrcode && <p className="text-danger">{erroreventqrcode}</p>}
                          
                                                     </Col> 
                          
                          
                          
                                                     <Col xl={6}>
                                                     <div className="mb-3">
                                                       <label htmlFor="cleave-date" className="form-label">Event Url</label>
                                                       <input placeholder="Enter Event Url" 
                                                    style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                    value={event_url}
                                                    onChange={handleeventurl}
                                                    onInput={(event) => {
                                                         event.target.style.borderColor = 'green';
                                                       }}
                                                       className="form-control" id="formFile"/><br/>
                                                     </div>
                                                     {errorEvent_url && <p className="text-danger">{errorEvent_url}</p>}
                          
                                                   </Col> 
                                                     </Row>
                              <Row>   
                          
                                                     <Col xl={6}>
                                                       <div className="mb-3">
                                                         <label htmlFor="cleave-date" className="form-label">Badge Text</label>
                                                         <input placeholder="Enter Badge Text" 
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                           event.target.style.borderColor = 'green';
                                                         }}
                                                         value={badge_text}
                                                         onChange={handlebadgetext}
                                                         className="form-control"  id="formFile"/><br/>
                                                       </div>
                                                       {errorbadge_text && <p className="text-danger">{errorbadge_text}</p>}
                          
                                                     </Col> 
                          
                                                     <Col xl={6}>
                                  <Label htmlFor="VertimeassageInput" className="form-label me-2">
                                    Badge Color
                                  </Label>
                                  <div className="mb-3 d-flex align-items-center">
                                    <input
                                      type="color"
                                      className="colorpicker me-2"
                                      value={badge_color}
                                      onChange={handlebadgecolor}
                                      style={{ border: 'none' }}
                              
                                    />
                                    <Cleave
                                      type="text"
                                      value={badge_color}
                                      onChange={handlebadgecolor}
                                      className="form-control"
                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                      onInput={(event) => {
                                        event.target.style.borderColor = 'green';
                                      }}
                                    />
                                  </div>
                                  {errorbadge_color && <p className="text-danger">{errorbadge_color}</p>}
                          
                                </Col>
                          
                          
                             </Row>
                              <Row>   
                               
                          
                                <Col xl={6}>
                                  <Label htmlFor="VertimeassageInput" className="form-label me-2">
                                    Event Color Code
                                  </Label>
                                  <div className="mb-3 d-flex align-items-center">
                                    <input
                                      type="color"
                                      className="colorpicker me-2"
                                      value={event_color_code}
                                      onChange={handleeventcolorcode}
                                      style={{ border: 'none' }}
                              
                                    />
                                    <Cleave
                                      type="text"
                                      value={event_color_code}
                                      onChange={handleeventcolorcode}
                                      className="form-control"
                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                      onInput={(event) => {
                                        event.target.style.borderColor = 'green';
                                      }}
                                    />
                                  </div>
                                  {errorevent_color_code && <p className="text-danger">{errorevent_color_code}</p>}
                          
                                </Col>
                                
                                <Col xl={6}>
                                  <Label htmlFor="VertimeassageInput" className="form-label me-2">
                                    App Bar Color Code
                                  </Label>
                                  <div className="mb-3 d-flex align-items-center">
                                    <input
                                      type="color"
                                      className="colorpicker me-2"
                                      value={event_appbar_color_code}
                                      onChange={handleappbarcolorcode}
                                      style={{ border: 'none' }}
                              
                                    />
                                    <Cleave
                                      type="text"
                                      value={event_appbar_color_code}
                                      onChange={handleappbarcolorcode}
                                      className="form-control"
                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                      onInput={(event) => {
                                        event.target.style.borderColor = 'green';
                                      }}
                                    />
                                  </div>
                                  {errorevent_appbar_color_code && <p className="text-danger">{errorevent_appbar_color_code}</p>}
                          
                                </Col>
                          
                    
                                <Col xl={6}>
                                  <Label htmlFor="VertimeassageInput" className="form-label me-2">
                                    Bottom Bar Color Code
                                  </Label>
                                  <div className="mb-3 d-flex align-items-center">
                                    <input
                                      type="color"
                                      className="colorpicker me-2"
                                      value={event_bottombar_color_code}
                                      onChange={handlebottomcolorcode}
                                      style={{ border: 'none' }}
                              
                                    />
                                    <Cleave
                                      type="text"
                                      value={event_bottombar_color_code}
                                      onChange={handlebottomcolorcode}
                                      className="form-control"
                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                      onInput={(event) => {
                                        event.target.style.borderColor = 'green';
                                      }}
                                    />
                                  </div>
                                  {errorevent_bottombar_color_code && <p className="text-danger">{errorevent_bottombar_color_code}</p>}
                          
                                </Col>
                               
                                <Col xl={6}>
                                  <Label htmlFor="VertimeassageInput" className="form-label me-2">
                          Bottom Background Color Code  
                          </Label>
                                  <div className="mb-3 d-flex align-items-center">
                                    <input
                                      type="color"
                                      className="colorpicker me-2"
                                      value={event_background_color_code}
                                      onChange={handlebackgroundcolor}
                                      style={{ border: 'none' }}
                              
                                    />
                                    <Cleave
                                      type="text"
                                      value={event_background_color_code}
                                      onChange={handlebackgroundcolor}
                                      className="form-control"
                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                      onInput={(event) => {
                                        event.target.style.borderColor = 'green';
                                      }}
                                    />
                                  </div>
                                  {errorevent_background_color_code && <p className="text-danger">{errorevent_background_color_code}</p>}
                          
                                </Col>
                          
                          
                            
                          
                          
                           </Row>
                           
                           
                          
                                <Row>
                                  <Col xl={6}>
                                    <div className="mb-3">
                                      <label htmlFor="event-type" className="form-label">
                                        Event Splash Type
                                      </label>
                                      <select
                                        id="event-type"
                                        className="form-select"
                                        style={{
                                          borderColor: "grey",
                                          boxShadow:
                                            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)",
                                        }}
                                        value={event_splash_type}
                                        onChange={handleSplashTypeChange}
                                      >
                                        <option>Select Event Type</option>
                                        <option value="none">None</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                        <option value="splash">Splash</option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                          
                                {event_splash_type === "image" && (
                                    <Row>   
                          
                          
                                        <Col xl={6}>
                                <div className="mb-3">
                                  <label htmlFor="splashpotrait" className="form-label">Potrait Logo Image</label>
                               
                                  
                                    
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="splashpotrait"
                                    accept="image/*"
                                    onChange={handlesplashpotrait}
                                  /> 
                                  
                                  <br/>
                                </div>
                              </Col> 
                              
                          <Col xl={6}>
                             <div className="mb-3">
                               <label htmlFor="splashlandscape" className="form-label">Landscape Logo Image</label>
                            
                               
                               <input
                                    type="file"
                                    className="form-control"
                                    id="splashlandscape"
                                    accept="image/*"
                                    onChange={handlesplashlandscape}
                                  />   
                               <br/>
                             </div>
                          
                           </Col> 
                                 
                                 </Row>
                                )}
                          
                          {event_splash_type === "video" && (
                                  <Row>
                                 <Col xl={6}>
                                <div className="mb-3">
                                  <label htmlFor="cleave-date" className="form-label">Potrait Logo URl</label>
                                  <input placeholder="Choose Potrait Logo" 
                               style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                          
                                 value={eventsplashurlportrait}
                                  onChange={handleportraitimage1}
                                  className="form-control" type="text" id="splashpotrait" /><br/>
                                </div>
                              </Col> 
                              
                          <Col xl={6}>
                             <div className="mb-3">
                               <label htmlFor="cleave-date" className="form-label">Landscape Logo URL</label>
                               <input placeholder="Choose Landscape Logo" 
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                value={eventsplashlandscape}
                               onChange={handlelandscape1}
                          
                               className="form-control" type="text" id="splashlandscape" /><br/>
                             </div>
                          
                           </Col> 
                                  </Row>
                                )}
                          
                          {event_splash_type === "splash" && (
                             <Row>   
                             <Col xl={6}>
                                <div className="mb-3">
                                  <label htmlFor="splashloader" className="form-label">Splash Loader</label>
                          
                                  
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="splashloader"
                                    accept="image/*"
                                    onChange={handlesplashloader}
                                  />
                                  
                                  <br/>
                                </div>
                          
                              </Col> 
                          
                          
                              <Col xl={6}>
                                <div className="mb-3">
                                  <label htmlFor="potrait" className="form-label">Potrait Logo Image</label>
                               
                                  
                                    
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="potrait"
                                    accept="image/*"
                                    onChange={handleportraitimage}
                                  /> 
                                  
                                  <br/>
                                </div>
                              </Col> 
                              
                          <Col xl={6}>
                             <div className="mb-3">
                               <label htmlFor="landscape" className="form-label">Landscape Logo Image</label>
                            
                               
                               <input
                                    type="file"
                                    className="form-control"
                                    id="landscape"
                                    accept="image/*"
                                    onChange={handlelandscape}
                                  />   
                               <br/>
                             </div>
                          
                           </Col> 
                          
                          </Row>
                          )}
                          
                          
                          
                          
                          
                          
                          
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           
                           <br/><br/><br/>
                             
                          
                                                    <div className="live-preview" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                    <Button
                            className="rounded-pill"
                            color="primary"
                            type="button"
                          
                            onClick={handleSubmit}
                          
                          >
                            <i className="ri-arrow-right-s-line" style={{ fontSize: "30px" }}></i>
                          </Button>
                          
                                                  </div>
                          
                                                    </div> 
                                                
                                                    
                                                  </div>
                                             
                                                </TabPane>
                          
                                                <TabPane id="steparrow-description-info" tabId={5}>
                          
                                               
                                                <Row className="g-3">
                                      <Col xxl={12}>
                                      <Row >
                          
                                      <Col xl={6}>
                                              <div className="mb-3">
                                                <label htmlFor="event-type" className="form-label">Event Key</label>
                                                <input placeholder="Enter Event Key" 
                                                      // minLength={5}
                                                      // maxLength={20}
                                                      value={event_key}
                                                      disabled
                                                      onChange={handleeventkey}
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                      className="form-control"/>
                                             
                                              </div>
                                              {errorEvent_key && <p className="text-danger">{errorEvent_key}</p>}
                          
                                      </Col>
                          
                                                  <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="cleave-date" className="form-label">Banner Title</label>
                                                      <input placeholder="Enter The Banner Title" 
                                                      // minLength={5}
                                                      // maxLength={20}
                                                      value={banner_title}
                                                      onChange={handlebannertitle}
                                                      style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                      onInput={(event) => {
                                                        event.target.style.borderColor = 'green';
                                                      }}
                                                      className="form-control"/><br/>
                                                    </div>
                                                    {errorbannertitle && <p className="text-danger">{errorbannertitle}</p>}
                          
                                                  </Col>
                                                  <Col xl={6}>
                            <div className="mb-3">
                              <label htmlFor="cleave-date" className="form-label">Banner Description</label>
                              <textarea
                                placeholder="Enter The Banner Description"
                                value={banner_description}
                                onChange={handledescription}
                                className="form-control"
                                style={{ borderColor: "grey", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)" }}
                                onInput={(event) => {
                                  event.target.style.borderColor = 'green';
                                }}
                              /><br />
                            </div>
                            {errorbannerdescription && <p className="text-danger">{errorbannerdescription}</p>}
                          </Col>
                          
                                                  </Row>
                          
                                                  <Row >
                                                
                          
                                                  <Col xl={6}>
                                                  <div className="mb-3">
                                                <label htmlFor="event-type" className="form-label">Banner Type</label>
                                                <select
                                                  id="event-type"
                                                  className="form-select"
                                                  value={banner_type}
                                                  onChange={handlebannertype}
                                                  style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                            
                                                >
                                                  <option >Select Banner Type</option>
                                                  <option value="LANDSCAPE">LANDSCAPE</option>
                                                  <option value="PORTRAIT">PORTRAIT</option>
                                                </select>
                                              </div>
                          
                                              {errorbannertype && <p className="text-danger">{errorbannertype}</p>}
                          
                                            </Col>
                                            <Col xl={6}>
                                                    <div className="mb-3">
                                                      <label htmlFor="cleave-date" className="form-label">Banner Image</label>
                                                                  <input type="file" className="form-control"
                                                    id="bannerimg" accept="image/*"
                                                    // value={Bannerimage}
                                                    // onChange={handlebannerimage}
                                                    style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                                                    onInput={(event) => {
                                                      event.target.style.borderColor = 'green';
                                                    }}
                                                  
                                                     /><br/>
                                                    </div>
                                                    {errorbannerimage && <p className="text-danger">{errorbannerimage}</p>}
                          
                                                  </Col>
                                           </Row>
                          
                                           
                          
                                                
                          
                          
                                      </Col>
                                    </Row>
                                    
                                    
                                    <br/><br/>
                                              
                                        
                                                  <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                      type="button"
                                                      className="btn btn-light btn-label previestab"
                                                      onClick={() => {
                                                        toggleArrowTab(activeArrowTab - 1);
                                                      }}
                                                    >
                                                      <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                      Back
                                                    </button>
                                                    <Button
                                                        className="rounded-pill right ms-auto nexttab nexttab"
                                                        style={{backgroundColor:"#4BB543", border:"none"}}
                            
                                                        type="button"
                                                      onClick={() => {
                                                        Addbanner(); 
                                                      }}
                                                    >
                                                      Submit
                                                    </Button>
                                                    
                            
                          
                                        </div>
                                                </TabPane>
                          
                                               
                                              </TabContent>
                                            </Form>
                                          </CardBody>
                                        </Card>
                                      </Col>
                          
                          
                          
                          
                          
                          
                          
                          
                          
                          
                                     
                                                      </Row><br/>
                                                    </Container>
                                  )}

       
     
                 
                 {/* )}                  */}
                        </React.Fragment>
                      );
                    };

                    export default AddEvent;
