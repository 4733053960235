


import {

  Label,
  Input,

} from "reactstrap";



import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import List from 'list.js';
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getcatchuplist,deletecatchuplist,getcatchupdata,updatecatchup} from '../../Apicalls/Catchup/Catchuplistdata';
import {geteventlist} from '../../Apicalls/Event/Eventlist';
import {getcategorylist} from '../../Apicalls/Catchup/Catchupcategorylist';

import  DataTable from 'react-data-table-component';
import Switch from '@mui/material/Switch';
import Session from "../../sessionmaindata"
// Import Images

const Sponserlist = () => {
    const [modal_list, setmodal_list] = useState(false);
    const tog_list = () => {
        setmodal_list(!modal_list);
    };

    const [modal_delete, setmodal_delete] = useState(false);
    const tog_delete = () => {
        setmodal_delete(!modal_delete);
    };
     



    
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [settingList, setsettingList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [editPackage, setEditPackage] = useState(null);

    const [Eventlist, setEventlist] = useState([]);
    const [eventname, seteventname] = useState([]);
    const [eventdata, seteventdata] = useState([]);
    const [categorydata, setcategorydata] = useState([]);





   
    const itemsPerPage = 10;
    const totalPages = Math.ceil(settingList.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = settingList.slice(indexOfFirstItem, indexOfLastItem);

 
  
  



    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await getcatchuplist();
        setEventlist(data);
        // setFilteredData(data);
        setIsLoading(false);
      };
  
      fetchData();
    }, []);
     

    async function handleEdit(ids) {
      const data = await getcatchupdata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await  geteventlist();
        seteventdata(data);
      };
  
      fetchData();
    }, []);



    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await  getcategorylist();
        setcategorydata(data);
      };
  
      fetchData();
    }, []);

 
    // useEffect(() => {
    //   const fetchData = async () => {
    //     // Simulating an API call or any asynchronous operation
    //     await new Promise((resolve) => setTimeout(resolve));
    //     const data = await getPlatformData();
    //     setplatformlist(data);
    //   //   setFilteredData(data);
    //     setIsLoading(false);
    //   };
  
    //   fetchData();
    // }, []);
  

  


   
    



   

    async function handleDelete(CatchupId) {
      const data = await deletecatchuplist(CatchupId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        handlePopupClose();

        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await getcatchuplist();
        setEventlist(updatedData);
      }
    }

  

    async function handleUpdate() { 
      // Get the updated data from your form inputs
  
          const updatedData = {
            id : editPackage.id,
            event_name : editPackage.event_name,
            event_category_id :editPackage.event_category_id,
            event_detail :editPackage.event_detail,
            event_url : editPackage.event_url     
      };
       const data = await updatecatchup(updatedData);
  
      if (data.error == true) {
        const error = data.message || 'Update failed';   
        $.alert({
          title: 'Error',
          content: error,
        }); // Fetch updated country list after successful update
      } else {
        handlePopupClose();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          const updatedData = await getcatchuplist();
          setEventlist(updatedData);
      }
    }


  
    
    const handlecatchupstatus = useCallback(async (id) => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  

        const response = await fetch(apiUrl +'/change_event_catchup_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ api_key: apiKey, id: id }),
        });
        const data = await response.json();
      } catch (error) {
        // console.log(error);
      }
      const updatedData = await getcatchuplist();
      setEventlist(updatedData);
  
    }, []);
   
  



  
      
 
  function handlePopupClose() {
    setmodal_list(false);
  }



    
  const columns = [
    {
      name: "Event Name",
      selector: row => row.event_name,  
      sortable: true,
    },
    {
      name: "Event Key",
      selector: row => row.event_key,  
      sortable: true,
    },
    {
      name: "Event Detail",
      selector: row => row.event_detail,  
      sortable: true,
    },


    {
        name: 'Event Status',
        cell: (row) => (
          <Switch
          checked={row.event_status.toLowerCase() === 'enable'}
          onChange={() => handlecatchupstatus(row.id)}
        />
        
        ),
   
        selector: row => row.event_status,  
        sortable: true,
      },
  
    //   {
    //     name: 'Ad Analytics',
    //     cell: (row) => (
    //             <Switch
    //             checked={row.advertisement_analytics.toLowerCase() === 'enable'}
    //             onChange={() => ToggleStatus1(row.advertisement_key)}
    //           />
    //     ),
   
    //     selector: row => row.advertisement_analytics,  
    //     sortable: true,
    //   },



    {
      name: <strong>Actions</strong>,
      cell: (row) => (
        <div>
   

          <li className="list-inline-item edit">
                  <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                  handleEdit(row.id);
                  tog_list()
                  }}
                  >
                  <i className="ri-edit-2-fill fs-16"></i>
                  </Link>
                  </li>
                  <li className="list-inline-item">
                  <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => handleDelete(row.id)}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];


  const filteredData = settingList.filter((channel) =>
  Object.values(channel).some((value) =>
    String(value).toLowerCase().includes(searchTerm.toLowerCase())
  )
);


const handleTimeout = () => {
  localStorage.removeItem('authToken');
};









    document.title = "Catchup List";

    return (
        <React.Fragment>
                              <Session timeout={1440000} onTimeout={handleTimeout} />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Catchup List" pageTitle="Tables" />
                    <Row>
                        
                        <Col lg={12}>
                            <Card>
                              
                            <CardHeader>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h4 className="card-title mb-0">Catchup List</h4>
                                      <Link to="/addcatchupdatas">
                                        <Button color="primary" className="add-btn me-1">
                                           Add Catchup &nbsp;
                                          <i className="ri-arrow-right-line align-bottom me-1"></i>
                                        </Button>
                                      </Link>
                                    </div>
                                  </CardHeader>
                  
                                  
                                  
                                <ToastContainer />  
                                        
                                <CardBody>
                                <Row className="g-4 mb-3">
                                    <Col className="col-sm">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div className="search-box">
                                          <input
                                            type="text"
                                            className="form-control search"
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                          />
                                          <i className="ri-search-line search-icon"></i>
                                      
                                        </div>
                             
                                      </div>
                                    </Col>
                                  </Row>

                        
                                  {isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                      columns={columns}
                                      data={Eventlist}
                                      pagination
                                    
                                    />
                                  )}
                                     </CardBody>
                              
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
            {/* Add Modal */}
            {editPackage && (
            <Modal className="modal-xl" isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" toggle={() => { tog_list(); }}>  
                 <span className="ribbon-three ribbon-three-primary"><span>Update Catchup</span></span></ModalHeader>
             
                <form className="tablelist-form">
                    <ModalBody>
                      <Row>

                      <Col xl={6}>
                        <div className="mb-3">
                          <label htmlFor="cleave-date" className="form-label">Event Key</label>
                          <select 
                            id="cleave-date" 
                            className="form-select" 
                            aria-label=".form-select-sm example"
                            value={editPackage.event_key}
                            onChange={e => setEditPackage({ ...editPackage, event_key: e.target.value })}         
                            disabled // Adding disabled attribute here
                          >
                            <option value={editPackage.event_key}>
                              Selected Eventkey -{editPackage.event_key}
                            </option>
                            {eventdata.map(category => (
                              <option key={category.event_key} value={category.event_key}>
                                {category.event_key}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
  
                     
                      <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      Vod Title
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter VOD Title"
                        value={editPackage.event_name} 
                        onChange={e => setEditPackage({ ...editPackage, event_name: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>

                    </Col>

                           

                  
  
                    <Col xl={6}>
                      <div className="mb-3">
                        <label htmlFor="cleave-date" className="form-label">Category Name</label>
                        <select
                          id="cleave-date"
                          className="form-select"
                          aria-label=".form-select-sm example"
                          value={editPackage.event_category_id}
                          onChange={e => setEditPackage({ ...editPackage, event_category_id: e.target.value })}
                        >
                          <option value={editPackage.event_category_name}>
                            Selected Event Category -{editPackage.event_category_name}
                          </option>
                          <option value="">Select Event Type</option>
                          {categorydata.map((event) => (
                            <option key={event.event_category_id} value={event.event_category_id}>
                              {event.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>


                    <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      VOD Details
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter VOD Details"
                        value={editPackage.event_detail} 
                        onChange={e => setEditPackage({ ...editPackage, event_detail: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>

                    </Col>


                       
                          <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">VOD Image</label>
                                          <br/>
                                          <img src={editPackage.event_banner} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="catchupimg" accept="image/*" />

                           </Col> 

                           </Row><br/>
                           <Row>

                        <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      VOD URl
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.event_url} 
                        onChange={e => setEditPackage({ ...editPackage, event_url: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>

                    </Col>
                    

    
 
 </Row>
 </ModalBody>      
                       <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={(handlePopupClose) => setmodal_list(false)}>Close</button>
                            <Button color="primary" 
                            onClick={handleUpdate}
                             >
                            Update
                          </Button> 
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
              )}
        </React.Fragment>
    );
};

export default Sponserlist;








