

// Get Banner List

const getbannerlist = async () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const user = window.sessionStorage.getItem("event_key"); 


    // const event_key = "LYCA23"

    

    const event_key = user; // or
try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/get_banner_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ api_key: apiKey,event_key })
      });
  
      const data = await response.json();
      return data.banner_list || [];
    } catch (error) {
      return [];
    }
  };


  
  const getbannerdata = async (ids) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/get_banner_data';
  

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: ids, api_key: apiKey })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch');
      }
  
      const data = await response.json();
      const {id,banner_url,event_key,banner_title,banner_description,banner_type
   } = data;

      return {id,banner_url,event_key,banner_title,banner_description,banner_type};
    } catch (error) {
      return null;
    }
  };



// DELETE Event Banner

const deletebanner = async (BannerId) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/delete_banner';
    const confirmed = await new Promise((resolve, reject) => {
              $.confirm({
                title: 'Confirm!',
                autoClose: "cancel|10000",
                content: 'Are you sure you want to delete this Event Banner!',
                type:"red",
                closeIcon: true,
                buttons: {
                  confirm: {
                    text: 'Confirm', 
                    btnClass: 'btn-red',
                    action: resolve,
                  },
                  cancel: function () {
                  },
                },
              });
            });
    if (confirmed) {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: BannerId, api_key: apiKey })
    });

    const data = await response.json();

    if (data.error) {
      const error = data.message || 'Delete failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  }} catch (error) {
    return { success: false, error: 'Delete failed' };
  }
};







const updatebanner = async (editData) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const url = process.env.REACT_APP_API_ENDPOINT+'/update_banner';


    var banner_image_upload = 'no';  

    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('id', editData.id);
    formData.append('banner_title', editData.banner_title);
    formData.append('banner_description', editData.banner_description);
    formData.append('banner_type', editData.banner_type);
 
    const bannerimg = document.getElementById('bannerimg');
    const eventbanner = bannerimg.files[0];
    if(eventbanner){
      banner_image_upload = 'yes';
    formData.append('banner_url', eventbanner);
    }
    formData.append('banner_image_upload', banner_image_upload);

    const response = await fetch(url, {
    method: 'POST',
    body: formData
    });

    const data = await response.json();


    if (data.error === true) {
      const error = data.message || 'Update failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  } catch (error) {
    return { success: false, error: 'Update failed' };
  }
};



  export {getbannerlist,deletebanner,getbannerdata,updatebanner};
  