



import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom';
import Switch from '@mui/material/Switch';
import {addcatchup} from '../../Apicalls/Catchup/Addcatchup';
import Session from "../../sessionmaindata"

import {
  
  Label,
  Input
  
} from "reactstrap";
import UiContent from "../../Components/Common/UiContent";
// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Button} from 'reactstrap';
import {addschedule} from '../../Apicalls/Schedule/Addschedule';
import {geteventlist} from '../../Apicalls/Event/Eventlist';





const Addschedule = () => {




    const [selectedEventType, setSelectedEventType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [eventdata, seteventdata] = useState([]);

    const [event_key, setevent_key] = useState("");
    const [event_type, setevent_type] = useState("");

    const [event_title, setevent_title] = useState("");
    const [event_sub_title, setevent_sub_title] = useState("");
    const [event_date, setevent_date] = useState("");
    const [event_place, setevent_place] = useState("");
    const [event_time, setevent_time] = useState("");
    const [event_duration, setevent_duration] = useState("");
    const [event_title1, setevent_title1] = useState("");
    const [event_title2, setevent_title2] = useState("");
    const [event_sub_title1, setevent_sub_title1] = useState("");
    const [event_sub_title2, setevent_sub_title2] = useState("");
    const [eventTypechange, setEventType] = useState('');


    const [event1logo, setevent1logo] = useState('');
    const [event2logo, setevent2logo] = useState('');
 




    const [errorevent_title, seterrorevent_title] = useState('');
    const [errorevent_type, seterrorevent_type] = useState('');
    const [errorevent_key, seterrorevent_key] = useState('');
    const [errorevent_sub_title, seterrorevent_sub_title] = useState('');
    const [errorevent_date, seterrorevent_date] = useState('');
    const [errorevent_place, seterrorevent_place] = useState('');
    const [errorevent_time, seterrorevent_time] = useState('');
    const [errorevent_duration, seterrorevent_duration] = useState('');
    const [errorevent_title1, seterrorevent_title1] = useState('');
    const [errorevent_title2, seterrorevent_title2] = useState('');
    const [errorevent_sub_title1, seterrorevent_sub_title1] = useState('');
    const [errorevent_sub_title2, seterrorevent_sub_title2] = useState('');
    const [errorevent1logo, seterrorevent1logo] = useState('');
    const [errorevent2logo, seterrorevent2logo] = useState('');




    const navigate = useNavigate();

     const Addschedules = async () => {
      if (isLoading) {
        return;
      }
      if (!event_type || !event_title || !event_key  || !event_sub_title || !event_date || !event_place || !event_time || !event_duration || !event_title1 || !event_title2
        || !event_sub_title1 || !event_sub_title2 || !event1logo || !event2logo) {
          seterrorevent_title(event_type ? "" : "Choose The Event Type");
          seterrorevent_title(event_title ? "" : "Enter The Event Title");
          seterrorevent_key(event_key ? "" : "Enter The Event Key");
          seterrorevent_sub_title(event_sub_title ? "" : "Enter The Event Subtitle");
          seterrorevent_date(event_date ? "" : "Enter The Event date");
          seterrorevent_place(event_place ? "" : "Enter The Event Place");
          seterrorevent_time(event_time ? "" : "Enter The Event Time");
          seterrorevent_duration(event_duration ? "" : "Enter The Event Duration");
          seterrorevent_title1(event_title1 ? "" : "Enter The Event Title1");
          seterrorevent_title2(event_title2 ? "" : "Enter The Event Title2");
          seterrorevent_sub_title1(event_sub_title1 ? "" : "Enter The Event Subtitle1");
          seterrorevent_sub_title2(event_sub_title2 ? "" : "Enter The Event Subtitle2");
          seterrorevent1logo(event1logo ? "" : "Choose the Event Logo A Image");
          seterrorevent2logo(event2logo ? "" : "Choose the Event Logo B Image");
      return;
    } 
        try {
          setIsLoading(true);
          const response = await addschedule(event_type,event_title,event_key,event_sub_title,event_date,event_place,event_time,event_duration,
            event_title1,event_title2,event_sub_title1,event_sub_title2,event1logo,event2logo);  
          navigate('/schedulelistdatas');
   
        } catch (error) {
          // Handle the error here
          console.error('Error:', error.message);
          // setError(error.message);
        } 
      }









      
 

      useEffect(() => {
        const fetchData = async () => {
          // Simulating an API call or any asynchronous operation
          await new Promise((resolve) => setTimeout(resolve));
          const data = await  geteventlist();
          seteventdata(data);
        };
    
        fetchData();
      }, []);




      const handletitle = (e) => {
        const input = e.target.value;
        if (input.length <= 25) {
          setevent_title(input);
          seterrorevent_title(''); // Clear any previous error message
        } else {
          seterrorevent_title('Event Schedule Title should be maximum 25 characters.'); // Set error message
        }
      };
      





      const handleEventTypeChange = (e) => {
        const input = e.target.value;
        setevent_key (input);
      };


      const handlesubtitle = (e) => {
        const input = e.target.value;
        if (input.length <= 35) {
          setevent_sub_title(input);
          seterrorevent_sub_title(''); // Clear any previous error message
        } else {
          seterrorevent_sub_title('Schedule SubTitle should be maximum 35 characters.'); // Set error message
        }
      };
      





      const handleeventdate = (e) => {
        const input = e.target.value;
        setevent_date (input);
      };

      const handleeventplace = (e) => {
        const input = e.target.value;
        setevent_place (input);
      };

      const handleeventtime = (e) => {
        const input = e.target.value;
        setevent_time (input);
      };

      // const handleduration = (e) => {
      //   const input = e.target.value;
      //   setevent_duration (input);
      // };

   
      
      const handleduration = (e) => {
        const input = e.target.value;
        // Regular expression to check if input consists only of numeric characters
        const isValidInput = /^[0-9]*$/.test(input);
        if (isValidInput || input === "") {
          setevent_duration(input);
          seterrorevent_duration(""); // Clear any previous error
        } else {
          seterrorevent_duration("Only numbers are allowed"); // Set error message
        }
      };
      
  

      const handletitle1 = (e) => {
        const input = e.target.value;
        setevent_title1 (input);
      };

      const handletitle2 = (e) => {
        const input = e.target.value;
        setevent_title2 (input);
      };

      const handlesubtitle1 = (e) => {
        const input = e.target.value;
        setevent_sub_title1 (input);
      };

      const handlesubtitle2 = (e) => {
        const input = e.target.value;
        setevent_sub_title2 (input);
      };
      const handleImageLogo1 = (event) => {
        const file = event.target.files[0];
        setevent1logo(file);
      };
      
      const handleImageLogo2 = (event) => {
        const file = event.target.files[0];
        setevent2logo(file);
      };
      // const handleeventtype = (e) => {
      //   const input = e.target.value;
      //   setevent_type  (input);
      // };
      // const handleChange = (event) => {
      //   setEventType(event.target.value);
      // };


      const handleChange = (e) => {
        const selectedEventType = e.target.value;
        setevent_type(selectedEventType);
    
        // If eventTypechange is marriage, corporate, or party, set event_title1 to "#"
        if (selectedEventType === 'marriage' || selectedEventType === 'corporate' || selectedEventType === 'party') {
            setevent_title1('#');
            setevent_title2('#');
            setevent_sub_title1('#');
            setevent_sub_title2('#');
            setevent1logo('#');
            setevent2logo('#');

        } else {
            // Otherwise, reset event_title1
            // Reset other states as needed
        }
    };
    
      
      

      const handleimagelogo1 = (event) => {
        const value = event.target.value;
        setevent1logo(value);
        seterrorevent1logo(value ? "" : "Choose the Event Logo A Image");
      };
   

      const handleimagelogo2 = (event) => {
        const value = event.target.value;
        setevent2logo(value);
        seterrorevent2logo(value ? "" : "Choose the Event Logo B Image");
      };
      const handleTimeout = () => {
        localStorage.removeItem('authToken');
      };
    


document.title ="Add Schedule";

  return (
    <React.Fragment>
 <Session timeout={1440000} onTimeout={handleTimeout} />
      <div className="page-content">
    
        
        <Container fluid>
          <BreadCrumb title=""  />
          <Row>
            <Col lg={12}> 
              <Card>
                <CardHeader>
                
                  <div className="d-flex justify-content-between align-items-center">
                   <h4 className="card-title mb-0">Add Schedule</h4>
                     <Link to="/schedulelistdatas">
                                        <Button color="primary" className="add-btn me-1">
                                           Schedule List &nbsp;
                                          <i className="ri-arrow-right-line align-bottom me-1"></i>
                                        </Button>
                                      </Link>
                  </div>
                </CardHeader>

                <CardBody >
                  <form action="#">
                    <div>
                    {/* <Col xl={6}>
      <div className="mb-3">
        <label htmlFor="event-type" className="form-label">Event Type</label>
        <select
          id="event-type"
          className="form-select"
          style={{ borderColor: "grey", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)" }}
          value={eventTypechange}
          onChange={handleChange}
        >
          <option value="">Select Event Type</option>
          <option value="sports">Sports</option>
          <option value="marriage">Marriage</option>
          <option value="corporate">Corporate Event</option>
          <option value="party">Party Event</option>
        </select>
      </div>

    </Col> */}

    <Col xl={6}>
                        <div className="mb-3">
  <label htmlFor="event-type" className="form-label">Event Type</label>
  <select
    id="event-type"
    className="form-select"
    style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
 
    value={event_type}
    onChange={handleChange}
    
  >
    <option value >Select Event Type</option>
    <option value="sports">Sports</option>
    <option value="marriage">Marriage</option>
    <option value="corporate">Corporate Event</option>
    <option value="party">Party Event</option>

  </select>
</div>
{errorevent_type && <p className="text-danger">{errorevent_type}</p>}



 
                        </Col>
 
                      <Row >

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Schedule Title</label>
                            <input placeholder="Enter Schedule Title" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_title}
                            onChange={handletitle}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
                          {errorevent_title && <p className="text-danger">{errorevent_title}</p>}

                        </Col>
               
               

                        <Col xl={6}>
                    <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Event Key</label>
                      <select
                        id="event-type"
                        className="form-select"
                        style={{
                          borderColor: "grey",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"
                        }}
                        value={event_key}
                        onChange={handleEventTypeChange}
                      >
                      <option value="">Select Event Type</option>
                     {eventdata.map((event) => (
                      <option key={'schedule_' + event.id} value={event.event_key}>
                        {event.event_key}
                      </option>
                    ))}
                </select>
                    </div>
                    {errorevent_key && <p className="text-danger">{errorevent_key}</p>}

                  </Col>
                    </Row>
                                    

                              <Row>
                                <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Schedule Subtitle</label>
                            <input placeholder="Enter Schedule Subtitle" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_sub_title}
                            onChange={handlesubtitle}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorevent_sub_title && <p className="text-danger">{errorevent_sub_title}</p>}

                        </Col>                   
                       </Row>
                

                       <Row>
                                <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Place</label>
                            <input placeholder="Enter Event Place" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_place}
                            onChange={handleeventplace}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorevent_place && <p className="text-danger">{errorevent_place}</p>}

                        </Col>  


                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Date</label>
                            <input  type="date" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_date}
                            onChange={handleeventdate}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
                          {errorevent_date && <p className="text-danger">{errorevent_date}</p>}

                        </Col>                 
                       </Row>




                       <Row>
                       <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Time</label>
                            <input  type="time" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_time}
                            onChange={handleeventtime}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
                          {errorevent_time && <p className="text-danger">{errorevent_time}</p>}
 
                        </Col>  


                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Duration</label>
                            <input  type="text" className="form-control" id="exampleInputdate" 
                            placeholder="Enter The Event Duration"
                            // minLength={5}
                            // maxLength={20}
                            value={event_duration}
                            onChange={handleduration}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
                          {errorevent_duration && <p className="text-danger">{errorevent_duration}</p>}

                        </Col>  
                         </Row>


                         {event_type === 'sports' && (
        <div className="mb-3">

<Row>
       <Col xl={6}>
        <div className="mb-3">
          <label htmlFor="lyca23-input" className="form-label">Team A Title</label>
          <input
            type="text"
            id="lyca23-input"
            className="form-control"
            placeholder="Enter Team A Title"
            value={event_title1}
            onChange={handletitle1}
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
        {errorevent_title1 && <p className="text-danger">{errorevent_title1}</p>}

    </Col>


    <Col xl={6}>
        <div className="mb-3">
          <label htmlFor="lyca23-input" className="form-label">Team B Title</label>
          <input
            type="text"
            id="lyca23-input"
            className="form-control"
            placeholder="Enter B Title"
            value={event_title2}
            onChange={handletitle2}
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
        {errorevent_title2 && <p className="text-danger">{errorevent_title2}</p>}

    </Col>
</Row>

<Row>
       <Col xl={6}>
        <div className="mb-3">
          <label htmlFor="lyca23-input" className="form-label">Team A Subtitle Title</label>
          <input
            type="text"
            id="lyca23-input"
            className="form-control"
            placeholder="Enter Team A Subtitle"
            value={event_sub_title1}
            onChange={handlesubtitle1}
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
        {errorevent_sub_title1 && <p className="text-danger">{errorevent_sub_title1}</p>}

    </Col>


    <Col xl={6}>
        <div className="mb-3">
          <label htmlFor="lyca23-input" className="form-label">Team B Subtitle Title</label>
          <input
            type="text"
            id="lyca23-input"
            className="form-control"
            placeholder="Enter Team b SubTitle"
            value={event_sub_title2}
            onChange={handlesubtitle2}
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
        {errorevent_sub_title2 && <p className="text-danger">{errorevent_sub_title2}</p>}

    </Col>
</Row>


<Row>
<Col xl={6}>
      <div className="mb-3">
        <label htmlFor="event1img" className="form-label">Team A Logo</label>
        <input
          type="file"
          className="form-control"
          id="event1img"
          accept="image/*"
          onChange={handleImageLogo1}
        />
      </div>
      {errorevent1logo && <p className="text-danger">{errorevent1logo}</p>}
    </Col>

  
    <Col xl={6}>
      <div className="mb-3">
        <label htmlFor="event2img" className="form-label">Team B Logo</label>
        <input
          type="file"
          className="form-control"
          id="event2img"
          accept="image/*"
          onChange={handleImageLogo2}
        />
      </div>
      {errorevent2logo && <p className="text-danger">{errorevent2logo}</p>}
    </Col>
</Row>

        </div>
      )}





                    </div>
                  
                    <div className="mt-4">
                      <Row>


                                 <div className="live-preview">
                                    <div className="d-flex flex-wrap gap-2">
                                    {/* <Button
                                      color="success"
                                      className="rounded-pill"
                                      onClick={addChannels}
                                    >
                                      Add channel
                                    </Button> */}

<Button
              className="btn btn-success rounded-pill"              
              id="add-btn"
              style={{width:"100px"}}
              onClick={Addschedules}
            >
Submit      </Button>
                                         </div>
                                           </div> 

                                        </Row>
                                      </div>
                                    </form>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                    
                        </div>
                        
                        </React.Fragment>
                      );
                    };

export default Addschedule;
