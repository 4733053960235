

// Get Event List

  const geteventlist = async () => {
    const apiKey = process.env.REACT_APP_API_KEY;
   
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/get_event_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ api_key: apiKey })
      });
  
      const data = await response.json();
      return data.event_details || [];
    } catch (error) {
      return [];
    }
  };

  
  // DELETE Event

const deleteEvent = async (EvendId) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/delete_event';
    const confirmed = await new Promise((resolve, reject) => {
              $.confirm({
                title: 'Confirm!',
                autoClose: "cancel|10000",
                content: 'Are you sure you want to delete this Event!',
                type:"red",
                closeIcon: true,
                // columnClass: "medium",
                buttons: {
                  confirm: {
                    text: 'Confirm',
                    btnClass: 'btn-red', 
                    action: resolve,
                  },
                  cancel: function () {
                  },
                },
              });
            });

    if (confirmed) {  
      
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: EvendId, api_key: apiKey })
    });
    const data = await response.json();

    if (data.error) {
      const error = data.message || 'Delete failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  }} catch (error) {
    return { success: false, error: 'Delete failed' };
  }
};

const geteventdata = async (ids) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/get_event_data';


  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: ids, api_key: apiKey })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    const {id,event_image,event_splash_loader,event_splash_url_portrait,event_splash_url_landscape,event_qr_code,event_url,event_name,event_sub_title,event_type,
      event_date,event_location,event_nature,event_splash_type,badge_text,badge_color,event_color_code,event_appbar_color_code,event_bottombar_color_code,
      event_background_color_code,event_schedule
 } = data;

    return {id,event_image,event_splash_loader,event_splash_url_portrait,event_splash_url_landscape,event_qr_code,event_url,event_name,event_sub_title,event_type,
      event_date,event_location,event_nature,event_splash_type,badge_text,badge_color,event_color_code,event_appbar_color_code,event_bottombar_color_code,
      event_background_color_code,event_schedule};
  } catch (error) {
    return null;
  }
};
 
const updateevent = async (editData) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const url = process.env.REACT_APP_API_ENDPOINT+'/update_event';

    var event1_image_upload = 'no';  
    var event2_image_upload = 'no';  
    var event3_image_upload = 'no';  
    var event4_image_upload = 'no';  
    var event5_image_upload = 'no';  


    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('id', editData.id);
    formData.append('event_url', editData.event_url);
    formData.append('event_name', editData.event_name);
    formData.append('event_sub_title', editData.event_sub_title);
    formData.append('event_type', editData.event_type);
    formData.append('event_nature', editData.event_nature);
    formData.append('event_splash_type', editData.event_splash_type);
    formData.append('event_date', editData.event_date);
    formData.append('event_location', editData.event_location);
    formData.append('badge_text', editData.badge_text);
    formData.append('badge_color', editData.badge_color);
    formData.append('event_color_code', editData.event_color_code);
    formData.append('event_appbar_color_code', editData.event_appbar_color_code);
    formData.append('event_bottombar_color_code', editData.event_bottombar_color_code);
    formData.append('event_background_color_code', editData.event_background_color_code);
    formData.append('event_schedule', editData.event_schedule);




    const bannerimg = document.getElementById('event_image');
    const eventbanner = bannerimg.files[0];
    if(eventbanner){
      event1_image_upload = 'yes';
    formData.append('event_image', eventbanner);
    }
    formData.append('event1_image_upload', event1_image_upload);


    const bannerimg1 = document.getElementById('event_splash_loader');
    const eventbanner1 = bannerimg1.files[0];
    if(eventbanner1){
      event2_image_upload = 'yes';
    formData.append('event_splash_loader', eventbanner1);
    }
    formData.append('event2_image_upload', event2_image_upload);


    const bannerimg2 = document.getElementById('event_splash_url_portrait');
    const eventbanner2 = bannerimg2.files[0];
    if(eventbanner2){
      event3_image_upload = 'yes';
    formData.append('event_splash_url_portrait', eventbanner1);
    }
    formData.append('event3_image_upload', event3_image_upload);

    const bannerimg3 = document.getElementById('event_splash_url_landscape');
    const eventbanner3 = bannerimg3.files[0];
    if(eventbanner3){
      event4_image_upload = 'yes';
    formData.append('event_splash_url_landscape', eventbanner1);
    }
    formData.append('event4_image_upload', event4_image_upload);

    const bannerimg4 = document.getElementById('event_qr_code');
    const eventbanner4 = bannerimg4.files[0];
    if(eventbanner4){
      event5_image_upload = 'yes';
    formData.append('event_qr_code', eventbanner1);
    }
    formData.append('event5_image_upload', event5_image_upload);

    const response = await fetch(url, {
    method: 'POST',
    body: formData
    });

    const data = await response.json();


    if (data.error === true) {
      const error = data.message || 'Update failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  } catch (error) {
    return { success: false, error: 'Update failed' };
  }
};




  export {geteventlist,deleteEvent,geteventdata,updateevent
  };
  