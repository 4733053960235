
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {addcatchup} from '../../Apicalls/Catchup/Addcatchup';
import { Button} from 'reactstrap';
import {geteventlist} from '../../Apicalls/Event/Eventlist';
import {getcategorylist} from '../../Apicalls/Catchup/Catchupcategorylist';
import Session from "../../sessionmaindata"


const Addcatchup = () => {
    const [selectedEventType, setSelectedEventType] = useState('');
    const [event_key, setevent_key] = useState("");
    const [event_name, setevent_name] = useState("");
    const [event_category_id, setevent_category_id] = useState("");
    const [event_detail, setevent_detail] = useState("");
    const [event_url, setevent_url] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [eventdata, seteventdata] = useState([]);
    const [categorydata, setcategorydata] = useState([]);
    const [errorEvent_key, setErrorevent_key] = useState([]);
    const [error_event_category_id, seterror_event_category_id] = useState([]);
    const [error_event_name, seterror_event_name] = useState([]);
    const [errorevent_detail, seterrorevent_detail] = useState([]);
    const [errorevent_url, seterror_event_url] = useState([]);



    const navigate = useNavigate();


      useEffect(() => {
        const fetchData = async () => {
          // Simulating an API call or any asynchronous operation
          await new Promise((resolve) => setTimeout(resolve));
          const data = await  geteventlist();
          seteventdata(data);
        };
    
        fetchData();
      }, []);




      useEffect(() => {
        const fetchData = async () => {
          // Simulating an API call or any asynchronous operation
          await new Promise((resolve) => setTimeout(resolve));
          const data = await  getcategorylist();
          setcategorydata(data);
        };
    
        fetchData();
      }, []);
    
    const Addcatchups = async () => {
      if (isLoading) {
        return;
      }
      if (!event_key || !event_name  || !event_category_id || !event_detail || !event_url) {
      setErrorevent_key(event_key ? "" : "Choose The Valid Event Key");
      seterror_event_name(event_name ? "" : "Enter the Event name");
      seterror_event_category_id(event_category_id ? "" : "Enter The Category Id");
      seterrorevent_detail(event_detail ? "" : "Enter The Event Details");
      seterror_event_url(event_url ? "" : "Enter The Event Url");
      return;
    }
        try {
          setIsLoading(true);
          const response = await addcatchup(event_key, event_name, event_category_id, event_detail, event_url);  
          navigate('/catchuplistdatas');

        } catch (error) {
          // console.log
        } finally {
          setIsLoading(false); // Reset loading to false after API call (success or error)
        } 
      }

      
      // const Addcatchups = async () => {
        
      //   try {
      //     setIsLoading(true);
      //     const response = await addcatchup(event_key, event_name, event_category_id, event_detail, event_url);  
      //     navigate('/catchuplistdatas');
   
      //   } catch (error) {
      //     // Handle the error here
      //     console.error('Error:', error.message);
      //     // setError(error.message);
      //   } 
      // }
       
      const handleeventkey = (e) => {
        const input = e.target.value;
        setevent_key (input);
      };


         
      // const handleeventname = (e) => {
      //   const input = e.target.value;
      //   setevent_name (input);
      // };

      const handleeventname = (e) => {
        const input = e.target.value;
        if (input.length <= 35) {
          setevent_name(input);
          seterror_event_name(''); // Clear any previous error message
        } else {
          seterror_event_name('VOD Title should be maximum 35 characters.'); // Set error message
        }
      };     


         
      const handlecategory = (e) => {
        const input = e.target.value;
        setevent_category_id(input);
      };


         
      // const handleeventdetail = (e) => {
      //   const input = e.target.value;
      //   setevent_detail (input);
      // };


      const handleeventdetail = (e) => {
        const input = e.target.value;
        if (input.length <= 75) {
          setevent_detail(input);
          seterrorevent_detail(''); // Clear any previous error message
        } else {
          seterrorevent_detail('VOD Details should be maximum 75 characters.'); // Set error message
        }
      };     

      // const handleeventurl = (e) => {
      //   const input = e.target.value;
      //   setevent_url (input);
      // };

      const handleeventurl = (e) => {
        const input = e.target.value;
        setevent_url(input);
      
        // Regular expression to check if the input is a valid URL starting with http:// or https://
        const urlRegex = /^(https?:\/\/)/;
      
        if (!urlRegex.test(input)) {
          seterror_event_url('Please enter a valid Link starting with "http://" or "https://".');
        } else {
          seterror_event_url('');
        }
    };
    


      const handleTimeout = () => {
        localStorage.removeItem('authToken');
      };
    
    
document.title ="Add Catchup";
  return (
    <React.Fragment>
                    <Session timeout={1440000} onTimeout={handleTimeout} />

      <div className="page-content"> 
        <Container fluid>
          <BreadCrumb title=""  />
          <Row>
            <Col lg={12}> 
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between align-items-center">
                   <h4 className="card-title mb-0">Add Catchup</h4>
                     <Link to="/catchuplistdatas">
                                        <Button color="primary" className="add-btn me-1">
                                           Catchup List &nbsp;
                                          <i className="ri-arrow-right-line align-bottom me-1"></i>
                                        </Button>
                                      </Link>
                  </div>
                </CardHeader>
                
                <CardBody >
                  <form action="#">
                    <div>
                      <Row>
                   <Col xl={6}>
                    <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Event Key</label>
                      <select
                        id="event-type"
                        className="form-select"
                        style={{
                          borderColor: "grey",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"
                        }}
                        value={event_key}
                        onChange={handleeventkey}
                      >
                      <option value="">Choose Event Key</option>
                     {eventdata.map((event) => (
                      <option key={'schedule_' + event.id} value={event.event_key}>
                        {event.event_key}
                      </option>
                    ))}
                   </select>
                    </div>
                    {errorEvent_key && <p className="text-danger">{errorEvent_key}</p>}

                  </Col>
                    <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Vod Title</label>
                            <input placeholder="Enter VOD Title" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_name}
                            onChange={handleeventname}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {error_event_name && <p className="text-danger">{error_event_name}</p>}

                        </Col></Row>




                   <Row>
                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="event-type" className="form-label">Category Name</label>
                            <select
                              id="event-type"
                              className="form-select"
                              style={{
                                borderColor: "grey",
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"
                              }}
                              onChange={handlecategory}
                            >
                              <option value="">Choose Category Name</option>
                                {categorydata.map((event) => (
                                  <option key={event.event_category_id} value={event.id}>
                                {event.name}
                              </option>
                              ))}
                            </select>
                          </div>
                          {error_event_category_id && <p className="text-danger">{error_event_category_id}</p>}
                       </Col>              



                    <Col xl={6}>
                            <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Vod Details</label>
                            <input placeholder="Enter Vod Details" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_detail}
                            onChange={handleeventdetail}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                            </div>
                            {errorevent_detail && <p className="text-danger">{errorevent_detail}</p>}

                        </Col>               
                       </Row>
                       
                       <Row>
                       <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Vod Image</label>
                            <input 
                            type='file'
                          id="eventimg" accept="image/*"
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                        </Col> 

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Vod Url</label>
                            <input placeholder="Enter Vod Url" 
                          
                            value={event_url}
                            onChange={handleeventurl}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorevent_url && <p className="text-danger">{errorevent_url}</p>}

                      </Col> 


                      </Row>
                    </div>
                  
                    <div className="mt-4">
                      <Row>
                 <div className="live-preview">
                                    <div className="d-flex flex-wrap gap-2">
                                    <Button
              className="btn btn-success rounded-pill"              
              id="add-btn"
              style={{width:"100px"}}
              onClick={Addcatchups}
            >
Submit      </Button>
                                         </div>
                                           </div> 

                                        </Row>
                                      </div>
                                    </form>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                    
                        </div>
                        
                        </React.Fragment>
                      );
                    };

export default Addcatchup;




