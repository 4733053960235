
const Addcategory = async (event_key,name,sort_order) => {
    try { 
      const apiKey = process.env.REACT_APP_API_KEY;
      const url = process.env.REACT_APP_API_ENDPOINT+'/add_category';

      const formData = new FormData();
      formData.append('api_key', apiKey);
      formData.append('event_key', event_key);
      formData.append('name', name);
      formData.append('sort_order', sort_order);

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      // console.log(data);
    } catch (error) {
    // console.log(error);
    }
  };

  export {Addcategory};
  