import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
 
  const [isApps, setIsApps] = useState(false);
  const [Eventschedule, setEventschedule] = useState(false);
  const [EventCatchup, setEventCatchup] = useState(false);

  const [isEmail, setEmail] = useState(false);
  const [Eventlistschedule, setEventlistschedule] = useState(false);
  const [EventCatchupdata, setEventCatchupdata] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Apps");
  const [iseventschedule, setiseventschedule] = useState("Event");
  const [iseventcatchup, setiseventcatchup] = useState("Catchup");




  

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
 
    if (iseventschedule !== "Event") {
      setEventschedule(false);
    }
    if (iseventcatchup !== "Catchup") {
      setEventCatchup(false);
    }
 

   
  }, [
    history,isApps,Eventschedule,EventCatchup]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
 
    {
      id: "apps",
      label: "Event",
      icon: "ri-calendar-event-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsApps(!isApps);
        setIscurrentState("Apps");
        updateIconSidebar(e);
      },
      stateVariables: isApps,
        subItems: [
          {
            id: 1,
            label: "Add Event",
            link: "/Addeventdatas",
            parentId: "apps",
          },
          {
            id: 2,
            label: "Event List",
            link: "/Eventlistdatas",
            parentId: "apps",
          },
       
  
        
       
      ],
    },
    {
      id: "event",
      label: "Event Schedule",
      icon: " ri-calendar-check-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setEventschedule(!Eventschedule);
        setiseventschedule("Event");
        updateIconSidebar(e);
      },
      stateVariables: Eventschedule,
      subItems: [
        {
          id: 1,
          label: "Add Schedule",
          link: "/addscheduledatas",
          parentId: "event",
        },
        {
          id: 2,
          label: "Schedule List",
          link: "/schedulelistdatas",
          parentId: "event",
        },
      ],
    },
    {
      id: "catchup",
      label: "Catchup",
      icon: "ri-play-circle-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setEventCatchup(!EventCatchup);
        setiseventcatchup("Catchup");
        updateIconSidebar(e);
      },
      stateVariables: EventCatchup,
        
        subItems: [
          {
            id: 3,
            label: "Category List",
            link: "/categorylisdata",
            parentId: "apps",
          },

          {
            id: 1,
            label: "Add Catchup",
            link: "/addcatchupdatas",
            parentId: "catchup",
          },
          {
            id: 2,
            label: "Catchup List",
            link: "/catchuplistdatas",
            parentId: "catchup",
          },
        
       
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
