
import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown,Container,Row,Col,Card,CardHeader,Button,CardBody,ModalBody,Modal,Label,ModalHeader,ModalFooter} from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Switch from '@mui/material/Switch';
import logoLight from "../../assets/images/avatar-8.jpg";
import {getlivelist,deletelive,Schedulelistdata,getlivedata,updatelive} from '../../Apicalls/Livedata/Livelist';
import Banner from './Banner.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Addlive} from '../../Apicalls/Livedata/Addlive';
import Session from "../../sessionmaindata"
const Livelist = () => {

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
      setmodal_list(!modal_list);
  };
    const [searchTerm, setSearchTerm] = useState('');
    const [Eventlist, setEventlist] = useState([]);
    const [scheduledata, setscheduledata] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isSchedule, setIsSchedule] = useState(false);
    const firstEventKey = Eventlist.length > 0 ? Eventlist[0].event_key : '';
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [editPackage, setEditPackage] = useState(null);


    const [event_url, setevent_url] = useState("");
    const [event_name, setevent_name] = useState("");
    const [event_description, setevent_description] = useState("");
    const [event_date, setevent_date] = useState("");
    const [event_time, setevent_time] = useState("");
    const [event_place, setevent_place] = useState("");
    const [event_schedule_id, setevent_schedule_id] = useState("");


    
    const [errorevent_url, seterrorevent_url] = useState("");
    const [errorevent_description, seterrorevent_description] = useState("");
    const [errorEvent_url, seterrorEvent_url] = useState("");





    useEffect(() => {
      const fetchData = async () => {
        await new Promise((resolve) => setTimeout(resolve));
        const data = await getlivelist();
        setEventlist(data);
        setIsLoading(false);
      };
      fetchData();
    }, []);


    async function handleDelete(BannerId) {
      const data = await deletelive(BannerId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await getlivelist();
        setEventlist(updatedData);
      }
    }


    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await  Schedulelistdata();
        setscheduledata(data);
      };
  
      fetchData();
    }, []);


    async function handleEdit(ids) {
      const data = await getlivedata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
      }
    }


    const toggle = () => {
        setIsEdit(!isEdit);
      };

      const handleClose = () => {
        toggle(); // Close the modal by toggling the state
      };


      // const handleScheduleChange = (event) => {
      //   setIsSchedule(event.target.value === 'yes');
      // };
      const handleScheduleChange = (event) => {
        setIsSchedule(event.target.value === 'yes');
        if (event.target.value === 'No') {
          setevent_schedule_id("0"); // Reset event_schedule_id when "No" is selected
        }
      };

      const Addlives = async () => {
        if (isLoading) {
          return;
        }
        try {    
          setIsLoading(true); 
          await Addlive(event_url,event_name,event_description,event_date,event_time,event_place,event_schedule_id);
          window.location.href = `/livelistdatas`;
  
        } catch (error) {
          // setError(error.message);
        } finally {
          setIsLoading(false); // Reset loading to false after API call (success or error)
        } 
      }

      async function handleUpdate() { 
        // Get the updated data from your form inputs
    
            const updatedData = {
              id : editPackage.id,
              event_name : editPackage.event_name,
              event_description :editPackage.event_description,
              event_date :editPackage.event_date,
              event_time :editPackage.event_time,
              event_place :editPackage.event_place,
              event_schedule_id :editPackage.event_schedule_id,
              event_url :editPackage.event_url,

        };
         const data = await updatelive(updatedData);
    
        if (data.error == true) {
          const error = data.message || 'Update failed';   
          $.alert({
            title: 'Error',
            content: error,
          }); // Fetch updated country list after successful update
        } else {
          handlePopupClose();
          toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            const updatedData = await getlivelist();
            setEventlist(updatedData);
        }
      }
   
      const handlelivestatus = useCallback(async (ids) => {
        try {
          const apiKey = process.env.REACT_APP_API_KEY;
          const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    
      
          const response = await fetch(apiUrl +'/change_event_live_status', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ api_key: apiKey, id: ids }),
          });
      
          const data = await response.json();
        } catch (error) {
              // console.log(data);

        }
        const updatedData = await getlivelist();
        setEventlist(updatedData);
    
      }, []);
  
      
      

      const handleeventurl = (e) => {
        const input = e.target.value;
        setevent_url(input);
      
        // Regular expression to check if the input is a valid URL starting with http:// or https://
        const urlRegex = /^(https?:\/\/)/;
      
        if (!urlRegex.test(input)) {
            seterrorEvent_url('Please enter a valid Link starting with "http://" or "https://".');
        } else {
            // console.log("Valid URL");
            seterrorEvent_url('');
        }
      };
      
      
      const handleeventname = (e) => {
        const input = e.target.value;
        if (input.length <= 20) {
          setevent_name(input);
          seterrorevent_url(''); // Clear any previous error message
        } else {
          seterrorevent_url('Live Name should be maximum 20 characters.'); // Set error message
        }
      };
      
   
      const handledescription = (e) => {
        const input = e.target.value;
        if (input.length <= 75) {
          setevent_description(input);
          seterrorevent_description(''); // Clear any previous error message
        } else {
          seterrorevent_description('Live Description should be maximum 75 characters.'); // Set error message
        }
      };

      const handledate = (e) => {
        const input = e.target.value;
        setevent_date(input);
      };



      const handletime = (e) => {
        const input = e.target.value;
        setevent_time(input);
      };

      const handleplace = (e) => {
        const input = e.target.value;
        setevent_place(input);
      };

      // const handleschedule = (e) => {
      //   const input = e.target.value;
      //   setevent_schedule_id(input);
      // };

      const handleschedule = (event) => {
        setevent_schedule_id(event.target.value);
      };
    

      // const handleeventstatus = useCallback(async (eventkey) => {
      //   try {
      //     const apiKey = process.env.REACT_APP_API_KEY;
      //     const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    
      
      //     const response = await fetch(apiUrl +'/change_event_live_status', {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //       },
      //       body: JSON.stringify({ api_key: apiKey, event_key: eventkey }),
      //     });
      
      //     const data = await response.json();
      //     console.log(data);
      //   } catch (error) {
      //     // console.log(error);
      //   }
      //   const updatedData = await geteventlist();
      //   setEventlist(updatedData);
    
      // }, []);
  
      
 
      function handlePopupClose() {
        setmodal_list(false);
      }

    const columns = [
    
        {
            name: <span className='font-weight-bold fs-13'>Event Name</span>,
            selector: row => row.event_name,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Event Place</span>,
          selector: row => row.event_place,
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Event Date</span>,
          selector: row => row.event_date,
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Event Time</span>,
          selector: row => row.event_time,
          sortable: true
        },  
        {
            name: 'Live Status',
            cell: (row) => (
              <Switch
              checked={row.status.toLowerCase() === 'enable'}
              onChange={() => handlelivestatus(row.id)}
            />
            ),
            selector: (row) => row.status,
            sortable: true,
          },
      
         
        {
            name: 'Action',
            cell: (row) => (
                <div>
      

                <li className="list-inline-item edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => {
                          handleEdit(row.id);
                          tog_list();
                        }}
                      >
                        <i className="ri-edit-2-fill fs-16"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleDelete(row.id)}
                      >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        
                      </Link>
                    </li>
      
      
              </div>

            ),
            selector: (row) => row.Status,
            sortable: true,
          },
        
    ];

    
    const filteredData = Eventlist.filter((event) =>
    Object.values(event).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };
    return (
      <React.Fragment>
<Session timeout={1440000} onTimeout={handleTimeout} />
        <Container fluid>  <br/>  <br/> 
        <BreadCrumb  pageTitle="" />
     
        <Row>
            <Col lg={12}>
                <Card>
              

                    <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0"><span>Livelist - </span>{firstEventKey}</h4>

  
</div>

                      </CardHeader>
<ToastContainer />
          
                    <CardBody>
                    <Row className="g-4 mb-3">
                        <Col className="col-sm">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <i className="ri-search-line search-icon"></i>
                          
                            </div>
                 
                          </div>
                        </Col>
                        <Col className="col-sm">

                        <div className="d-flex justify-content-between align-items-center">
  <h4 className="card-title mb-0"></h4>
  <div className="d-flex align-items-center">
     
  <div>
  <Link to="/addscheduledatas">
      <Button color="primary">Add Schedule &nbsp;</Button>
    </Link>&nbsp;&nbsp;
      <Button color="primary" onClick={toggle}>
        Add Live
      </Button>

      <Modal
        isOpen={isEdit}
        toggle={toggle}
        centered
        size="xl"
        className="border-0"
        modalClassName="modal fade zoomIn"
      >
            <ModalHeader className="p-3 bg-soft-info" toggle={toggle} >
          {!!isEdit ? "Add Live" : "update"}
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row className="g-3">
            <Col xxl={12}>
            <Row >

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Live Name</label>
                            <input placeholder="Enter Live Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_name}
                            onChange={handleeventname}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorevent_url && <p className="text-danger">{errorevent_url}</p>}
                        </Col>
                        <Col xl={6}>
                        <div className="mb-3">
                          <label htmlFor="cleave-date" className="form-label">Live Description</label>
                          <textarea
                            placeholder="Enter Live Description"
                 
                            value={event_description}
                            onChange={handledescription}
                            className="form-control"
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                          /><br />

                        </div>
                        {errorevent_description && <p className="text-danger">{errorevent_description}</p>}

                        </Col>
                        </Row>

                        <Row >
                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Live Date</label>
                            <input  type="date" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_date}
                            onChange={handledate}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
 
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-time" className="form-label">Live Time</label>
                            <input  type="time" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_time}
                            onChange={handletime}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>

                        </Col>
                 </Row>

                 <Row >
                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Live Place</label>
                            <input  type="text" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={event_place}
                            onChange={handleplace}
                            placeholder="Enter Live Place"
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>

                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Live Url</label>
                            <input  type="text" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={200}
                            value={event_url}
                            onChange={handleeventurl}
                            placeholder="Enter Live Url"
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
                          {errorEvent_url && <p className="text-danger">{errorEvent_url}</p>}

                        </Col>
                 </Row>
                 <Row >
                      
    

                         <Col xl={6}>
      <label htmlFor="cleave-date" className="form-label">
        Is Schedule
      </label>

      <div>
        <input
          type="radio"
          id="html"
          name="Player1"
          value="yes"
          onChange={handleScheduleChange}
        />
        &nbsp;&nbsp;
        <label htmlFor="Yes">Yes</label>
        <br />
        <input
          type="radio"
          id="css"
          name="Player1"
          value="No"
          onChange={handleScheduleChange}
        />
        &nbsp;
        <label htmlFor="No">No</label>
        <br />
      </div>
      </Col>
      <Col xl={6}>

      {isSchedule && (
        <div>
          <label htmlFor="scheduleDropdown" className="form-label">
            Select Schedule
          </label><br/>
          <select
      id="cleave-date"
      className="form-select"
      aria-label=".form-select-sm example"
      onChange={handleschedule}
    >
      <option value="">Select a Package List</option>
      {scheduledata.map((schedule) => (
        <option key={'schedule_' + schedule.id} value={schedule.id}>
          {schedule.event_title}
        </option>
      ))}
    </select>
        </div>
      )}
    </Col>

   
       


       


                 </Row>

            </Col>
          </Row>
        </ModalBody>
        <div   style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Button
              type="submit"
              className="btn btn-success rounded-pill"              
              id="add-btn"
              style={{width:"100px"}}
              onClick={Addlives}
            >
Submit      </Button></div>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn-light" onClick={handleClose}>
              Close
            </Button>
   
          </div>
        </div>
      </Modal>
    </div>
    
    &nbsp;&nbsp;
    <Link to="/Eventlistdatas">
      <Button color="primary">Event List &nbsp;</Button>
    </Link>
  </div>
</div>
</Col>

                      </Row>

     


{isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    highlightOnHover={true} 
                                    
                                />
                                  )}



            
                         </CardBody>
                  
                </Card>
            </Col>
        </Row>

    </Container>
    {editPackage && (
  <Modal className="modal-xl zoom" isOpen={modal_list} toggle={tog_list} centered>
    <ModalHeader className="bg-light p-3" toggle={tog_list}>
      Update Live List
    </ModalHeader>
    <form className="tablelist-form">
      <ModalBody>
<Row>
                  <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                    Event Name
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.event_name} 
                        onChange={e => setEditPackage({ ...editPackage, event_name: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>   

               <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                    Event Description
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter Event Description"
                        value={editPackage.event_description} 
                        onChange={e => setEditPackage({ ...editPackage, event_description: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>   

                       <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="text" className="form-label">Event Date</label>
                            <input className="form-control" id="exampleInputdate" 
                           
                            value={editPackage.event_date} 
                            onChange={e => setEditPackage({ ...editPackage, event_date: e.target.value })}
                           
                            /><br/>
                          </div>
 
                        </Col>


                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="text" className="form-label">Event Time</label>
                            <input className="form-control" id="exampleInputdate" 
                           
                            value={editPackage.event_time} 
                            onChange={e => setEditPackage({ ...editPackage, event_time: e.target.value })}
                           
                            /><br/>
                          </div>
 
                        </Col>



                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="text" className="form-label">Event Place</label>
                            <input className="form-control" id="exampleInputdate" 
                           
                            value={editPackage.event_place} 
                            onChange={e => setEditPackage({ ...editPackage, event_place: e.target.value })}
                           
                            /><br/>
                          </div>
                        </Col>


      <Col xl={6}>

              <div>
                  <label htmlFor="scheduleDropdown" className="form-label">
                    Select Schedule
                  </label><br/>
                  <select
              id="cleave-date"
              className="form-select"
              aria-label=".form-select-sm example"
              value={editPackage.event_schedule_id}
              onChange={e => setEditPackage({ ...editPackage, event_schedule_id: e.target.value })}
            >
                <option value={editPackage.event_schedule_name}>
                                    Selected Event Category -{editPackage.event_schedule_name}
                                  </option>
              <option value="">Select a Package List</option>
              {scheduledata.map((schedule) => (
                <option key={'schedule_' + schedule.id} value={schedule.id}>
                  {schedule.event_title}
                </option>
              ))}
            </select>
                </div>
                
        </Col>

   


        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="text" className="form-label">Event URl</label>
                            <input className="form-control" id="exampleInputdate" 
                           
                            value={editPackage.event_url} 
                            onChange={e => setEditPackage({ ...editPackage, event_url: e.target.value })}
                           
                            /><br/>
                          </div>
 
                        </Col>




                      </Row>












      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <button type="button" className="btn btn-light" onClick={handlePopupClose}>
            Close
          </button>
          <Button color="primary" onClick={handleUpdate} >
            Update
          </Button>
        </div>
      </ModalFooter>
    </form>
  </Modal>
)}

    </React.Fragment>
      
    );
};



export default Livelist;


