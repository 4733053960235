
// Add Catchup

const addcatchup = async (event_key,event_name,event_category_id,event_detail,event_url) => {
    try { 
      const apiKey = process.env.REACT_APP_API_KEY;
      const url = process.env.REACT_APP_API_ENDPOINT+'/add_catchup';

      const eventimg = document.getElementById('eventimg');
      const eventimage = eventimg.files[0];

      const formData = new FormData();
      formData.append('api_key', apiKey);
      formData.append('event_key', event_key);
      formData.append('event_name', event_name);
      formData.append('event_category_id', event_category_id);
      formData.append('event_detail', event_detail);
      formData.append('event_url', event_url);
      formData.append('event_banner', eventimage);
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      // console.log(data);
    } catch (error) {
    // console.log(error);
    }
  };
  export {addcatchup};
  

