import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown,Container,Row,Col,Card,CardHeader,Button,CardBody,ModalBody,Modal,ModalHeader,Label,ModalFooter} from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Switch from '@mui/material/Switch';
import {geteventlist,deleteEvent,geteventdata,updateevent} from '../../Apicalls/Event/Eventlist';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from './Banner.css';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import Session from "../../sessionmaindata"
import myloader from "../../assets/images/loader.gif"


const BasicTable = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
      setmodal_list(!modal_list);
  };

    const [searchTerm, setSearchTerm] = useState('');
    const [Eventlist, setEventlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [editPackage, setEditPackage] = useState(null);

  
   
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;



    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await geteventlist();
        setEventlist(data);
        // setFilteredData(data);
        setIsLoading(false);
      };
  
      fetchData();
    }, []);
    


    const handleeventstatus = useCallback(async (eventkey) => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  
    
        const response = await fetch(apiUrl +'/change_event_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ api_key: apiKey, event_key: eventkey }),
        });
    
        const data = await response.json();
      } catch (error) {
            // console.log(data);

      }
      const updatedData = await geteventlist();
      setEventlist(updatedData);
  
    }, []);
  
  

    async function handleDelete(EventId) {
      const data = await deleteEvent(EventId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await geteventlist();
        setEventlist(updatedData);
      }
    }

    async function handleUpdate() { 
      
          const updatedData = {
            id : editPackage.id,
            event_url : editPackage.event_url,
            event_name :editPackage.event_name,
            event_sub_title :editPackage.event_sub_title,
            event_type :editPackage.event_type,
            event_date :editPackage.event_date,
            event_sub_title :editPackage.event_sub_title,
            event_location :editPackage.event_location,
            badge_text :editPackage.badge_text,
            event_nature :editPackage.event_nature,
            event_splash_type :editPackage.event_splash_type,
            badge_color :editPackage.badge_color,
            event_color_code :editPackage.event_color_code,
            event_appbar_color_code :editPackage.event_appbar_color_code,
            event_bottombar_color_code :editPackage.event_bottombar_color_code,
            event_background_color_code :editPackage.event_background_color_code,
            event_schedule :editPackage.event_schedule,
      };
       const data = await updateevent(updatedData);
  
      if (data.error == true) {
        const error = data.message || 'Update failed';   
        $.alert({
          title: 'Error',
          content: error,
        }); // Fetch updated country list after successful update
      } else {
        handlePopupClose();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          const updatedData = await geteventlist();
          setEventlist(updatedData);
      }
    }

 


    const handleWatchLiveClick = (event_key) => {
      // console.log(event_key);
   
    };
     

   async function handleEdit(ids) {
      const data = await geteventdata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
        // console.log(data);
      }
    }

    function handlePopupClose() {
      setmodal_list(false);
    }


    const columns = [
      
        {
            name: <span className='font-weight-bold fs-13'>Event Name</span>,
            selector: row => row.event_name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Event Key</span>,
            selector: row => row.event_key,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Event Type</span>,
            selector: row => row.event_type,
            sortable: true
        },

        {
          name: 'Event Status',
          cell: (row) => (
            <Switch
              checked={row.event_status === 'ENABLE'}
              onChange={() => handleeventstatus(row.event_key)}
            />
          ),
          selector: (row) => row.event_status,
          sortable: true,
        },
          {
            name: 'Status',
            cell: (row) => (
                <> 
               
                <Link 
                  data-eventid={row.event_key}
                  onClick={() => {
                      sessionStorage.setItem('event_key', row.event_key);
                      handleWatchLiveClick(row.event_key);
                      window.location.href = `/bannerlistdatas`;
                  }}
                >
                <Button style={{width:"80px",backgroundColor:"#1c1c35",border:"none"}}>Banner</Button>
                </Link>
                
                &nbsp;
                
                <Link     data-eventid={row.event_key}
                  onClick={() => {
                      sessionStorage.setItem('event_key', row.event_key);
                      handleWatchLiveClick(row.event_key);
                      window.location.href = `/livelistdatas`;

                  }}>
                <Button style={{width:"80px",backgroundColor:"red",border:"none"}}>Live</Button>
                </Link>
                </>
         

            ),
            selector: (row) => row.Status,
            sortable: true,
          },

            
        {
            name: 'Action',
            cell: (row) => (
                <div>
      

                <li className="list-inline-item edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => {
                          handleEdit(row.id);
                          tog_list();
                        }}
                      >
                        <i className="ri-edit-2-fill fs-16"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleDelete(row.id)}
                        >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        
                      </Link>
                    </li>
      
      
              </div>

            ),
            selector: (row) => row.Status,
            sortable: true,
          },
        
    ];

    const filteredData = Eventlist.filter((event) =>
    Object.values(event).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
    
  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };

    return (
      <React.Fragment>
        
    
   <Session timeout={1440000} onTimeout={handleTimeout} />
        <Container fluid>  <br/>  <br/> 
        <BreadCrumb   />
     
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader>
                        <div className="d-flex align-items-center justify-content-between">
                              <h4 className="card-title mb-0">Event List</h4>
                              <div className="d-flex align-items-center">
                                  <Link to="/addscheduledatas">
                                      <Button color="primary">Add Schedule &nbsp;</Button>
                                  </Link>&nbsp;&nbsp;
                                  <Link to="/Addeventdatas">
                                      <Button color="primary" className="add-btn me-1">
                                          Add Event &nbsp;
                                          <i className="ri-arrow-right-line align-bottom me-1"></i>
                                      </Button>
                                  </Link>
                              </div>
                        </div>
                        </CardHeader>
                        <ToastContainer />  



                   
                      
                      
                            
                    <CardBody>
                    <Row className="g-4 mb-3">
                        <Col className="col-sm">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <i className="ri-search-line search-icon"></i>
                          
                            </div>
                 
                          </div>
                        </Col>
                      </Row>

     
   

                               {isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    highlightOnHover={true} 
                                    
                                />
                                  )}

 </CardBody>
                  
                </Card>
            </Col>
        </Row>

    </Container>

    {editPackage && (
  <Modal className="modal-xl zoom" isOpen={modal_list} toggle={tog_list} centered>
    <ModalHeader className="bg-light p-3" toggle={tog_list}>
      Update  Event List
    </ModalHeader>
    <form className="tablelist-form">
      <ModalBody>
        <Row>
        <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      Event Name                     
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.event_name} 
                        onChange={e => setEditPackage({ ...editPackage, event_name: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>  
               <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      Event Subtitle                     
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.event_sub_title} 
                        onChange={e => setEditPackage({ ...editPackage, event_sub_title: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>  

               <Col xl={6}>
                        <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Event Type</label>
                      <select
                        id="event-type"
                        className="form-select"
                        value={editPackage.event_type} 
                        onChange={e => setEditPackage({ ...editPackage, event_type: e.target.value })}
                        style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                  
                      >
                       <option value={editPackage.event_type}>
                          Selected Type -{editPackage.event_type}
                        </option>
                        <option value="sports">Sports</option>
                        <option value="party">Part</option>
                        <option value="corporate">corporate</option>
                        <option value="marriage">Marriage</option>

                      </select>
                    </div>

 
                  </Col>

                  {/* <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="cleave-date" className="form-label">Event Date</label>
                 
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter Event Date"
                        value={editPackage.event_date} 
                        onChange={e => setEditPackage({ ...editPackage, event_date: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>   */}

               <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Event Date</label>
                            <input  type="date" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_date} 
                            onChange={e => setEditPackage({ ...editPackage, event_date: e.target.value })}
                            // onChange={handlechannelname}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>

                        </Col>


               <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      Event Location                     
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.event_location} 
                        onChange={e => setEditPackage({ ...editPackage, event_location: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>  

                                     <Col lg={6}>
                                                  <label htmlFor="cleave-date-format" className="form-label">Event Nature</label>
                                                  <div>
                                                    <input
                                                      type="radio"
                                                      id="catch21"
                                                      name="catchup12"
                                                      value="PUBLIC"
                                                      checked={editPackage.event_nature === "PUBLIC"}
                                                      onChange={e => setEditPackage({ ...editPackage, event_nature: e.target.value })}
                                                      />
                                                    <label htmlFor="catch1">Public </label><br/>
                                                    <input
                                                      type="radio"
                                                      id="catch21"
                                                      name="catchup12"
                                                      value="PRIVATE"
                                                      checked={editPackage.event_nature === "PRIVATE"}
                                                      onChange={e => setEditPackage({ ...editPackage, event_nature: e.target.value })}
                                                    />
                                                    <label htmlFor="catch2">Private</label><br/>
                                                 
                                                  </div>
                                                </Col>
                                                
                                                <Col xl={6}>
                        <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Event Splash Type</label>
                      <select
                        id="event-type"
                        className="form-select"
                        value={editPackage.event_splash_type} 
                        onChange={e => setEditPackage({ ...editPackage, event_splash_type: e.target.value })}
                        style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                  
                      >
                       <option value={editPackage.event_splash_type}>
                          Selected Type -{editPackage.event_splash_type}
                        </option>
                        <option value="image">IMAGE</option>
                        <option value="video">VIDEO</option>
                        <option value="splash">SPLASH</option>

                      </select>
                    </div>

 
                  </Col>




                  <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                    Badge Text   </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.badge_text} 
                        onChange={e => setEditPackage({ ...editPackage, badge_text: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
               </Col>  

               <Col xl={6}>
        <Label htmlFor="VertimeassageInput" className="form-label me-2">
          Badge Color
        </Label>
        <div className="mb-3 d-flex align-items-center">
          <input
            type="color"
            className="colorpicker me-2"
            value={editPackage.badge_color} 
            onChange={e => setEditPackage({ ...editPackage, badge_color: e.target.value })}
            style={{ border: 'none' }}
    
          />
          <Cleave
            type="text"
            value={editPackage.badge_color} 
            onChange={e => setEditPackage({ ...editPackage, badge_color: e.target.value })}
            className="form-control"
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
      </Col>


      <Col xl={6}>
        <Label htmlFor="VertimeassageInput" className="form-label me-2">
Event Color Code

        </Label>
        <div className="mb-3 d-flex align-items-center">
          <input
            type="color"
            className="colorpicker me-2"
            value={editPackage.event_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_color_code: e.target.value })}
            style={{ border: 'none' }}
    
          />
          <Cleave
            type="text"
            value={editPackage.event_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_color_code: e.target.value })}
            className="form-control"
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
      </Col>



      <Col xl={6}>
        <Label htmlFor="VertimeassageInput" className="form-label me-2">
Event App Bar Color Code

        </Label>
        <div className="mb-3 d-flex align-items-center">
          <input
            type="color"
            className="colorpicker me-2"
            value={editPackage.event_appbar_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_appbar_color_code: e.target.value })}
            style={{ border: 'none' }}
    
          />
          <Cleave
            type="text"
            value={editPackage.event_appbar_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_appbar_color_code: e.target.value })}
            className="form-control"
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
      </Col>

      <Col xl={6}>
        <Label htmlFor="VertimeassageInput" className="form-label me-2">
Event Bar Color Code

        </Label>
        <div className="mb-3 d-flex align-items-center">
          <input
            type="color"
            className="colorpicker me-2"
            value={editPackage.event_bottombar_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_bottombar_color_code: e.target.value })}
            style={{ border: 'none' }}
    
          />
          <Cleave
            type="text"
            value={editPackage.event_bottombar_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_bottombar_color_code: e.target.value })}
            className="form-control"
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
      </Col>


      <Col xl={6}>
        <Label htmlFor="VertimeassageInput" className="form-label me-2">
Event Background Color Code

        </Label>
        <div className="mb-3 d-flex align-items-center">
          <input
            type="color"
            className="colorpicker me-2"
            value={editPackage.event_background_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_background_color_code: e.target.value })}
            style={{ border: 'none' }}
    
          />
          <Cleave
            type="text"
            value={editPackage.event_background_color_code} 
            onChange={e => setEditPackage({ ...editPackage, event_background_color_code: e.target.value })}
            className="form-control"
            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
            onInput={(event) => {
              event.target.style.borderColor = 'green';
            }}
          />
        </div>
      </Col>







                                                 <Col lg={6}>
                                                  <label htmlFor="cleave-date-format" className="form-label">Event Schedule</label>
                                                  <div>
                                                    <input
                                                      type="radio"
                                                      id="catch1"
                                                      name="catchup1"
                                                      value="YES"
                                                      checked={editPackage.event_schedule === "YES"}
                                                      onChange={e => setEditPackage({ ...editPackage, event_schedule: e.target.value })}
                                                      />
                                                    <label htmlFor="catch1">Yes</label><br/>
                                                    <input
                                                      type="radio"
                                                      id="catch2"
                                                      name="catchup1"
                                                      value="NO"
                                                      checked={editPackage.event_schedule === "NO"}
                                                      onChange={e => setEditPackage({ ...editPackage, event_schedule: e.target.value })}
                                                    />
                                                    <label htmlFor="catch2">No</label><br/>
                                                 
                                                  </div>
                                                </Col>



                             <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Event Image</label>
                                          <br/>
                                          <img src={editPackage.event_image} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event_image" accept="image/*" />

                           </Col> 


                           <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Event Splash Loader</label>
                                          <br/>
                                          <img src={editPackage.event_splash_loader} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event_splash_loader" accept="image/*" />

                           </Col> 


                           <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Event Splash URL Potrait</label>
                                          <br/>
                                          <img src={editPackage.event_splash_url_portrait} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event_splash_url_portrait" accept="image/*" />

                           </Col> 

                           <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Event Splash URL Landscape</label>
                                          <br/>
                                          <img src={editPackage.event_splash_url_landscape} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event_splash_url_landscape" accept="image/*" />

                           </Col> 

                           <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Event Qr Code</label>
                                          <br/>
                                          <img src={editPackage.event_qr_code} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event_qr_code" accept="image/*" />

                           </Col> 

        </Row>
    
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <button type="button" className="btn btn-light" onClick={handlePopupClose}>
            Close
          </button>
          <Button color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </div>
      </ModalFooter>
    </form>
  </Modal>
)}


</React.Fragment>
      
    );
};



export default BasicTable;


