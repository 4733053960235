

// Get Catchup List

const getcatchuplist = async () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const user = window.sessionStorage.getItem("event_key"); 
    const event_key = user; 
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/get_catchup_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ api_key: apiKey,event_key })
      });

      const data = await response.json();
      return data.catchup_list || [];
    } catch (error) {
      return [];
    }
  };  

// DELETE Catchup

const getcatchupdata = async (ids) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/get_catchup_data';

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: ids, api_key: apiKey })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    const {id, event_name, event_category_id,event_category_name, event_detail,event_banner,event_url,event_key
 } = data;

   

    return {id, event_name, event_category_id,event_category_name, event_detail,event_banner,event_url,event_key};
  } catch (error) {
    return null;
  }
};


const deletecatchuplist = async (CatchupId) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/delete_catchup';
    const confirmed = await new Promise((resolve, reject) => {
              $.confirm({
                title: 'Confirm!',
                autoClose: "cancel|10000",
                content: 'Are you sure you want to delete this Event Catchup!',
                type:"red",
                closeIcon: true,
                buttons: {
                  confirm: {
                    text: 'Confirm', 
                    btnClass: 'btn-red',
                    action: resolve,
                  },
                  cancel: function () {
                  },
                },
              });
            });
    if (confirmed) {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: CatchupId, api_key: apiKey })
    });

    const data = await response.json();

    if (data.error) {
      const error = data.message || 'Delete failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  }} catch (error) {
    return { success: false, error: 'Delete failed' };
  }
};


const updatecatchup = async (editData) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const url = process.env.REACT_APP_API_ENDPOINT+'/update_catchup';
    var catchup_image_upload = 'no';  
    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('id', editData.id);
    formData.append('event_name', editData.event_name);
    formData.append('event_category_id', editData.event_category_id);
    formData.append('event_detail', editData.event_detail);
    formData.append('event_url', editData.event_url);
 
    const catchupimg = document.getElementById('catchupimg');
    const eventbanner = catchupimg.files[0];
    if(eventbanner){
      catchup_image_upload = 'yes';
    formData.append('event_banner', eventbanner);
    }
    formData.append('catchup_image_upload', catchup_image_upload);

    const response = await fetch(url, {
    method: 'POST',
    body: formData
    });

    const data = await response.json();


    if (data.error === true) {
      const error = data.message || 'Update failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  } catch (error) {
    return { success: false, error: 'Update failed' };
  }
};



export {getcatchuplist,deletecatchuplist,getcatchupdata,updatecatchup};
  