import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import {Container,Row,Col,Card,CardHeader,Button,CardBody,Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Switch from '@mui/material/Switch';
import {getcategorylist,deletecatchupcategory,getcategorydata,updatecategory} from '../../Apicalls/Catchup/Catchupcategorylist';
import {Addcategory} from '../../Apicalls/Catchup/Addcategory';
import {geteventlist} from '../../Apicalls/Event/Eventlist';
import Session from "../../sessionmaindata"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import category from './category.css';

const Categorylist = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
      setmodal_list(!modal_list);
  };

    const [searchTerm, setSearchTerm] = useState('');
    const [Eventlist, setEventlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [editPackage, setEditPackage] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [eventdata, seteventdata] = useState([]);

    const [event_key, setevent_key] = useState("");
    const [name, setname] = useState("");
    const [sort_order, setsort_order] = useState("");

   
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [errorevent_key, setErrorevent_key] = useState("");
    const [errorname, setErrorname] = useState("");
    const [errorsort_orde, setErrorsort_order] = useState("");


    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await getcategorylist();
        setEventlist(data);
        // setFilteredData(data);
        setIsLoading(false);
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await  geteventlist();
        seteventdata(data);
      };
  
      fetchData();
    }, []);

    async function handleUpdate() { 
      // Get the updated data from your form inputs
  
          const updatedData = {
            id : editPackage.id,
            name : editPackage.name,
            sort_order :editPackage.sort_order,
      
      };
       const data = await updatecategory(updatedData);
  
      if (data.error == true) {
        const error = data.message || 'Update failed';   
        $.alert({
          title: 'Error',
          content: error,
        }); // Fetch updated country list after successful update
      } else {
        handlePopupClose();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          const updatedData = await getcategorylist();
          setEventlist(updatedData);
      }
    }


    const toggle = () => {
      setIsEdit(!isEdit);
    };

    const handleClose = () => {
      toggle(); // Close the modal by toggling the state
    };



    async function handleDelete(EventId) {
      const data = await deletecatchupcategory(EventId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await getcategorylist();
        setEventlist(updatedData);
      }
    }



    const addcategoryes = async () => {
      if (isLoading) {
        return;
      }
      if (!event_key || !name  || !sort_order) {
      setErrorevent_key(event_key ? "" : "Choose The Valid Event Key");
      setErrorname(name ? "" : "Enter The category Name");
      setErrorsort_order(sort_order ? "" : "Enter The Sort Order Number");
      return;
    }
      try {    
        setIsLoading(true); 
        await Addcategory(event_key,name,sort_order);
        window.location.href = `/categorylisdata`;

      } catch (error) {
        // setError(error.message);
      } finally {
        setIsLoading(false); // Reset loading to false after API call (success or error)
      } 
    }


    // const handleeventkey = (e) => {
    //   const input = e.target.value;
    //   setevent_key (input);
    // };


    // const handlename = (e) => {
    //   const input = e.target.value;
    //   setname (input);
    // };

    // const handlesortorder = (e) => {
    //   const input = e.target.value;
    //   setsort_order (input);
    // };


    const handleeventkey = (event) => {
      const value = event.target.value;
      setevent_key(value);
      setErrorevent_key(value ? "" : "Choose The Valid Event Key");
    };


    // const handlename = (event) => {
    //   const value = event.target.value;
    //   setname(value);
    //   setErrorname(value ? "" : "Enter The category Name");
    // };

    const handlename = (e) => {
      const input = e.target.value;
      if (input.length <= 35) {
        setname(input);
        setErrorname(''); // Clear any previous error message
      } else {
        setErrorname('Category name should be maximum 35 characters.'); // Set error message
      }
    };


    // const handlesortorder = (event) => {
    //   const value = event.target.value;
    //   setsort_order(value);
    //   setErrorsort_order(value ? "" : "Enter The Sort Number");
    // };

    const handlesortorder = (e) => {
      const input = e.target.value;
      // Regular expression to check if input consists only of numeric characters
      const isValidInput = /^[0-9]*$/.test(input);
      if (isValidInput || input === "") {
        setsort_order(input);
        setErrorsort_order(""); // Clear any previous error
      } else {
        setErrorsort_order("Enter Positive Number"); // Set error message
      }
    };
    

    async function handleEdit(ids) {
      const data = await getcategorydata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
      }
    }


    const handleWatchLiveClick = (event_key) => {
   
    };
     
    function handlePopupClose() {
      setmodal_list(false);
    }


    const columns = [
      
     
        {
            name: <span className='font-weight-bold fs-13'>Event Key</span>,
            selector: row => row.event_key,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Category Name</span>,
            selector: row => row.name,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Sort Order</span>,
          selector: row => row.sort_order,
          sortable: true
        },
       
        {
            name: 'Action',
            cell: (row) => (
                <div>
                <li className="list-inline-item edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => {
                          handleEdit(row.id);
                          tog_list();
                        }}
                      >
                        <i className="ri-edit-2-fill fs-16"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleDelete(row.id)}
                        >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        
                      </Link>
                    </li>
      
      
              </div>

            ),
            selector: (row) => row.Status,
            sortable: true,
          },
        
    ];

    const filteredData = Eventlist.filter((event) =>
    Object.values(event).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };

  
    return (
      <React.Fragment>
      <Session timeout={1440000} onTimeout={handleTimeout} />
  <Container fluid>  <br/>  <br/> 
        <BreadCrumb   />
        <Row>
            <Col lg={12}>
                 <Card>
                   <CardHeader>   
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="card-title mb-0">Category List</h4>
                         
                          <div>
      <Button color="primary" onClick={toggle}>
        Add Category
      </Button>

      <Modal
        isOpen={isEdit}
        toggle={toggle}
        centered
        size="xl"
        className="border-0"
        modalClassName="modal fade zoomIn"
      >
            <ModalHeader className="p-3 bg-soft-info" toggle={toggle} >
          {!!isEdit ? "Add Category" : "update"}
        </ModalHeader>
        <ModalBody className="modal-body">
          <Row className="g-3">
            <Col xl={6}>
                    <div className="mb-3">
                      <label htmlFor="event-type" className="form-label">Event Key</label>
                      <select
                        id="event-type"
                        className="form-select"
                        style={{
                          borderColor: "grey",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"
                        }}
                        value={event_key}
                        onChange={handleeventkey}
                      >
                      <option value="">Select Event Type</option>
                     {eventdata.map((event) => (
                      <option key={'schedule_' + event.id} value={event.event_key}>
                        {event.event_key}
                      </option>
                    ))}
                </select>
                    </div>
                    {errorevent_key && <p className="text-danger">{errorevent_key}</p>}

                  </Col>

                      <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Category Name</label>
                            <input placeholder="Enter category Name" 
                            value={name}
                            onChange={handlename}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorname && <p className="text-danger">{errorname}</p>}
                        </Col>
                        


                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Sort Order</label>
                            <input placeholder="Enter Sort Order" 
                            // minLength={5}
                            // maxLength={20}
                            value={sort_order}
                            onChange={handlesortorder}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            className="form-control"/><br/>
                          </div>
                          {errorsort_orde && <p className="text-danger">{errorsort_orde}</p>}

                        </Col>



                        </Row>
        </ModalBody>
        <div   style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Button
              type="submit"
              className="btn btn-success rounded-pill"              
              id="add-btn"
              style={{width:"100px"}}
              onClick={addcategoryes}
        >
         Submit    
          </Button></div>
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn-light" onClick={handleClose}>
              Close
            </Button>
   
          </div>
        </div>
      </Modal>
    </div>

                        </div>
                      </CardHeader>
                      <ToastContainer /> 
                      <CardBody>
                    <Row className="g-4 mb-3">
                        <Col className="col-sm">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <i className="ri-search-line search-icon"></i>
                          
                            </div>
                 
                          </div>
                        </Col>
                      </Row>


{isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    highlightOnHover={true}   
                                />
                                
                                  )}
 </CardBody>
  </Card>
</Col>

</Row>
    </Container>


    {editPackage && (
            <Modal className="modal-xl" isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" toggle={() => { tog_list(); }}>  
                 <span className="ribbon-three ribbon-three-primary"><span>Update Category</span></span></ModalHeader>
             
                <form className="tablelist-form">
                    <ModalBody>
                      <Row>

                      <Col xl={6}>
                      <div className="mb-3">
                      <label htmlFor="package-name" className="form-label">
                      Category Name
                      </label>
                      <input
                        type="text"
                        id="package-name"
                        placeholder="Enter package name"
                        value={editPackage.name} 
                        onChange={e => setEditPackage({ ...editPackage, name: e.target.value })}
                        // onChange={handlechannelname}
                        className="form-control"
                      />
                    </div>
                    </Col>   

                        <Col xl={6}>
                          <div className="mb-3">
                           <label htmlFor="package-name" className="form-label">
                              Sort Order
                               </label>
                                  <input
                                  type="text"
                                  id="package-name"
                                  placeholder="Enter package name"
                                  value={editPackage.sort_order} 
                                  onChange={e => setEditPackage({ ...editPackage, sort_order: e.target.value })}
                                  // onChange={handlechannelname}
                                  className="form-control"
                                  />
                          </div>
                        </Col> 

 
                           </Row>
                     
 </ModalBody>      
                       <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={(handlePopupClose) => setmodal_list(false)}>Close</button>
                            <Button color="primary" 
                            onClick={handleUpdate}
                             >
                            Update
                          </Button> 
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
              )}

</React.Fragment>
      
    );
};
export default Categorylist;















