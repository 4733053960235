


import React, { useEffect, useState,useRef } from 'react';

import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row, Button } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";
import {Addlogin} from '../../Apicalls/Adminlogin/Login';


//import images
import logoLight from "../../assets/images/logo-light.png";



const BasicSignIn = () => {
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [logintype, setLogin] = useState('');
    const [userpassword, setUserpassword] = useState('');
    const [error, setError] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);


    // const Login = async () => {
    //     if (isLoading) {
    //         return;
    //     }
    
    //     if (!logintype || !userpassword) {
    //         setError('Please enter the username and password');
    //         return;
    //     }
    
    //     try {
    //         setIsLoading(true);
    //         const response = await Addlogin(logintype, userpassword);
    
    //         if (!response || !response.ok) {
    //             const data = await response?.json(); // Using optional chaining for safety
    
    //             // Handle non-JSON response or empty response
    //             const errorMessage = data?.message || 'Please enter the correct username and password';
    //             setError(errorMessage);
    //         } else {
    //             // Clear error on successful login
    //             setError('');
    //         }
    //     } catch (error) {
    //         setError(error.message);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }



    
    const Login = async () => {
        try {
          setIsLoading(true);
    
          // Retrieve other form fields
          const apiKey = process.env.REACT_APP_API_KEY;
          const url = process.env.REACT_APP_API_ENDPOINT + '/user_login';
    
          const formData = new FormData();
          formData.append('api_key', apiKey);
          formData.append('username', logintype);
          formData.append('password', userpassword);
    
          // Check if username or password is empty
          if (!logintype || !userpassword) {
            throw new Error('Please enter the username and password');
          }
    
          // Make the POST request
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          if (!response.ok) {
            const data = await response.json();
            setError('Please enter the correct username and password');
          } else {
            const data = await response.json();
            // Save the session token in sessionStorage
            
            saveSessionData(data.token);
            // Redirect to the dashboard or perform any other action
            window.location.href = '/Addeventdatas';
          }
        } catch (error) {
          console.error(error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };
    


      const saveSessionData = (token) => {
        sessionStorage.setItem('token', token);
        // You can also store additional user data if needed
      };

      const handlelogin = (event) => {
        setLogin(event.target.value);
      };
       

      const handlepassword = (event) => {
        setUserpassword(event.target.value);
      };

      const togglePasswordVisiblity = () => {
        setPasswordShow(passwordShow ? false : true);
      };





      

    document.title = "Eventplay Cms";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                       
                     <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4 card-bg-fill">
                                    <CardBody className="p-4">
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="50" />
                                        </Link>
                                    </div>
                                </div>
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <form action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">Username</Label>
                                                    <Input
                                                type="text"
                                                placeholder='Enter The Username'
                                                id="username"
                                                name="username"
                                                value={logintype}
                                                onChange={handlelogin}
                                                ref={inputRef}
                                                />  
                                                </div>
                                                <div className="mb-3">
                                                   
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div style={{ position: "relative" }} className="mb-3">
                                                                <Input
                                                                    placeholder="Password"
                                                                    name="password"
                                                                    type={passwordShow ? "text" : "password"}
                                                                    value={userpassword}
                                                                    onChange={handlepassword}
                                                                    style={{ paddingRight: "30px" }} // Create space for the icon
                                                                />
                                                                <i
                                                                    className={`ri-eye-fill align-middle ${passwordShow ? "show" : ""}`}
                                                                    onClick={togglePasswordVisiblity}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "50%",
                                                                        right: "10px",
                                                                        transform: "translateY(-50%)",
                                                                        cursor: "pointer",
                                                                    }}
                                                                ></i>
                                                            </div>
                                                </div> 
                                                <div className="mt-4">
                                                {error && <p style={{color: 'red'}}>{error}</p>}
                                                <div className="mt-4">
                                                <button
                                                type="button"
                                                className="btn btn-success rounded-pill"
                                                onClick={Login}
                                                disabled={isLoading}
                                                >
                                                {isLoading ? 'Signing In...' : 'Sign In'}
                                                </button>
                                                </div>

                                                </div>

                                             
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>


                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default BasicSignIn;