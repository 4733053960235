import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown,Container,Col,Card,CardHeader,Button,CardBody,Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Switch from '@mui/material/Switch';
import {getschedulelist,deleteschedule,getscheduledata,updateschedule} from '../../Apicalls/Schedule/Schedulelist';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import schedule from './schedule.css';
import Session from "../../sessionmaindata"

const Schedulelist = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
      setmodal_list(!modal_list);
  };
    const [searchTerm, setSearchTerm] = useState('');
    const [Eventlist, setEventlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [editPackage, setEditPackage] = useState(null);

  
   
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;



    useEffect(() => {
      const fetchData = async () => {
        // Simulating an API call or any asynchronous operation
        await new Promise((resolve) => setTimeout(resolve));
        const data = await getschedulelist();
        setEventlist(data);
        // setFilteredData(data);
        setIsLoading(false);
      };
  
      fetchData();
    }, []);
    


    async function handleDelete(EventId) {
      const data = await deleteschedule(EventId);
      if (data.error) {
        const error = data.message || 'Update failed';
        $.alert(data.message); 
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        
        const updatedData = await getschedulelist();
        setEventlist(updatedData);
      }
    }

    async function handleUpdate() { 
      // Get the updated data from your form inputs
  
          const updatedData = {
            id : editPackage.id,
            event_title : editPackage.event_title,
            event_subtitle :editPackage.event_subtitle,
            event_date :editPackage.event_date,
            event_place :editPackage.event_place,
            event_time :editPackage.event_time,
            event_duration :editPackage.event_duration,
            event_title1 :editPackage.event_title1,
            event_title2 :editPackage.event_title2,
            event_sub_title1 :editPackage.event_sub_title1,
            event_sub_title1 :editPackage.event_sub_title1,


      };
       const data = await updateschedule(updatedData);
  
      if (data.error == true) {
        const error = data.message || 'Update failed';   
        $.alert({
          title: 'Error',
          content: error,
        }); // Fetch updated country list after successful update
      } else {
        handlePopupClose();
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          const updatedData = await getschedulelist();
        setEventlist(updatedData);
      }
    }


    const handleWatchLiveClick = (event_key) => {
   
    };
     
      
 
    function handlePopupClose() {
      setmodal_list(false);
    }

    async function handleEdit(ids) {
      const data = await getscheduledata(ids);
  
      if (data) {
        setEditPackage(data);
        // setShowPopup(true);
      }
    }


   
    const handleschedulestatus = useCallback(async (id) => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  

        const response = await fetch(apiUrl +'/change_event_schedule_status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ api_key: apiKey, id: id }),
        });
        const data = await response.json();
      } catch (error) {
        // console.log(error);
      }
      const updatedData = await getschedulelist();
      setEventlist(updatedData);
  
    }, []);



    const columns = [
      
        {
            name: <span className='font-weight-bold fs-13'>Event Title</span>,
            selector: row => row.event_title,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Event Key</span>,
            selector: row => row.event_key,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Event Date</span>,
            selector: row => row.event_date,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Event Time</span>,
          selector: row => row.event_time,
          sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Event Place</span>,
          selector: row => row.event_place,
          sortable: true
        },
        // {
        //   name: 'Schedule Status',
        //   cell: (row) => (
        //     <Switch
        //     checked={row.event_status === 'ENABLE'}
        //     onChange={() => handleschedulestatus(row.id)}
        //   />
        //   ),
        //   selector: (row) => row.event_status,
        //   sortable: true,
        // },
        {
          name: 'Schedule Status',
          cell: (row) => (
            <Switch
              checked={row.event_status.toLowerCase() === 'enable'}
              onChange={() => handleschedulestatus(row.id)}
            />
          ),
          selector: (row) => row.event_status,
          sortable: true,
        },
       
        {
            name: 'Action',
            cell: (row) => (
                <div>
      

                <li className="list-inline-item edit">
                      <Link
                        to="#"
                        className="text-primary d-inline-block edit-item-btn"
                        onClick={() => {
                          handleEdit(row.id);
                          tog_list();
                        }}
                      >
                        <i className="ri-edit-2-fill fs-16"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="text-danger d-inline-block remove-item-btn"
                        onClick={() => handleDelete(row.id)}
                        >
                        <i className="ri-delete-bin-5-fill fs-16"></i>
                        
                      </Link>
                    </li>
      
      
              </div>

            ),
            selector: (row) => row.Status,
            sortable: true,
          },
        
    ];

    const filteredData = Eventlist.filter((event) =>
    Object.values(event).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
    
  const handleTimeout = () => {
    localStorage.removeItem('authToken');
  };
    return (
      <React.Fragment>
<Session timeout={1440000} onTimeout={handleTimeout} />
        <Container fluid>  <br/>  <br/> 
        <BreadCrumb  />
     
        <Row>
            <Col lg={12}>
                <Card>
              

                    <CardHeader>
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="card-title mb-0">Schedulelist List</h4>
                          <Link to="/addscheduledatas">
                            <Button color="primary" className="add-btn me-1">
                                Add Schedule &nbsp;
                              <i className="ri-arrow-right-line align-bottom me-1"></i>
                            </Button>
                          </Link>
                        </div>
                      </CardHeader>
                      <ToastContainer />  



                   
                      
                      
                            
                    <CardBody>
                    <Row className="g-4 mb-3">
                        <Col className="col-sm">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="search-box">
                              <input
                                type="text"
                                className="form-control search"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <i className="ri-search-line search-icon"></i>
                          
                            </div>
                 
                          </div>
                        </Col>
                      </Row>

     


{isLoading ? (
                                      <div className="loader-container"></div>
                                  ) : (
                                    <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    highlightOnHover={true} 
                                    
                                />
                                
                                  )}

            
                         </CardBody>
                  
                </Card>
            </Col>
        </Row>

    </Container>


    {editPackage && (
  <Modal className="modal-xl zoom" isOpen={modal_list} toggle={tog_list} centered>
    <ModalHeader className="bg-light p-3" toggle={tog_list}>
      Update Schedule List
    </ModalHeader>
    <form className="tablelist-form">
      <ModalBody>
        <Row>
        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Schedule Title</label>
                            <input placeholder="Enter Schedule Title" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_title} 
                            onChange={e => setEditPackage({ ...editPackage, event_title: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>



                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Schedule Subtitle</label>
                            <input placeholder="Enter Schedule Subtitle" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_subtitle} 
                            onChange={e => setEditPackage({ ...editPackage, event_subtitle: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>



                    

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Date</label>
                            <input  type="date" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_date} 
                            onChange={e => setEditPackage({ ...editPackage, event_date: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>

                        </Col>      


                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Place</label>
                            <input placeholder="Enter Event Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_place} 
                            onChange={e => setEditPackage({ ...editPackage, event_place: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>

                     

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Time</label>
                            <input  type="time" className="form-control" id="exampleInputdate" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_time} 
                            onChange={e => setEditPackage({ ...editPackage, event_time: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            onInput={(event) => {
                              event.target.style.borderColor = 'green';
                            }}
                            /><br/>
                          </div>
 
                        </Col>  

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Duration</label>
                            <input placeholder="Enter The Event Duration" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_duration} 
                            onChange={e => setEditPackage({ ...editPackage, event_duration: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Team A Title</label>
                            <input placeholder="Enter Event Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_title1} 
                            onChange={e => setEditPackage({ ...editPackage, event_title1: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Team b Title</label>
                            <input placeholder="Enter Event Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_title2} 
                            onChange={e => setEditPackage({ ...editPackage, event_title2: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Team A Subtitle</label>
                            <input placeholder="Enter Event Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_sub_title1} 
                            onChange={e => setEditPackage({ ...editPackage, event_sub_title1: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <label htmlFor="cleave-date" className="form-label">Team B Subtitle</label>
                            <input placeholder="Enter Event Name" 
                            // minLength={5}
                            // maxLength={20}
                            value={editPackage.event_sub_title2} 
                            onChange={e => setEditPackage({ ...editPackage, event_sub_title2: e.target.value })}
                            style={{ borderColor:"grey",boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0)"}}
                            className="form-control"/><br/>
                          </div>
 
                        </Col>


                        <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Team A Logo</label>
                                          <br/>
                                          <img src={editPackage.event1_logo} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event1_logo" accept="image/*" />

                           </Col> 

                           <Col xl={6}>
                                          <div className="mb-3">
                                          <label htmlFor="cleave-date" className="form-label">Team B Logo</label>
                                          <br/>
                                          <img src={editPackage.event2_logo} className="img-fluid avatar-xl" alt="Sorry Image Not Found" />
                                            </div>

                                            <input className="form-control" type="file" id="event2_logo" accept="image/*" />

                           </Col> 

               
        </Row>
        
        
        
        </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <button type="button" className="btn btn-light" onClick={handlePopupClose}>
            Close
          </button>
          <Button color="primary"  onClick={handleUpdate} >
            Update
          </Button>
        </div>
      </ModalFooter>
    </form>
  </Modal>
)}





</React.Fragment>
      
    );
};



export default Schedulelist;


