import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import maintenanceImg from '../../assets/images/maintenance.png';

const Errorpage = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    // Set the document title
    React.useEffect(() => {
        document.title = "Event Play";
    }, []);

    // Handle redirection when the component is unmounted
    React.useEffect(() => {
        return () => {
            navigate('/login'); // Redirect to the login page when unmounted
        };
    }, [navigate]);

    return (
        <React.Fragment>            
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 pt-4">
                                    <div className="mb-5 text-white-50">
                                        <h1 className="display-5 coming-soon-text">Session Expired</h1>
                                        <p className="fs-14">Back To Login</p>
                                        <div className="mt-4 pt-2">
                                            <Link to="/login" className="btn btn-success"><i className="mdi mdi-home me-1"></i>Login Page</Link>
                                        </div>
                                    </div>
                                    <Row className="justify-content-center mb-5">
                                        <Col xl={4} lg={8}>
                                            <div>
                                                <img src={maintenanceImg} alt="" className="img-fluid" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
}

export default Errorpage;
