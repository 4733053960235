




const addschedule = async (event_type,event_title,event_key,event_sub_title,event_date,event_place,event_time,event_duration,event_title1,event_title2,event_sub_title1,event_sub_title2,
  event1logo,event2logo) => {
  try { 
    const apiKey = process.env.REACT_APP_API_KEY;
    const url = process.env.REACT_APP_API_ENDPOINT+'/add_schedule'; 




    // const event1img = document.getElementById('event1img');
    // const event1logo = event1img.files[0];

    // const event2img = document.getElementById('event2img');
    // const event2logo = event2img.files[0];



    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('event_type', event_type);
    formData.append('event_title', event_title);
    formData.append('event_key', event_key);
    formData.append('event_sub_title', event_sub_title);
    formData.append('event_date', event_date);
    formData.append('event_place', event_place);
    formData.append('event_time', event_time);
    formData.append('event_duration', event_duration);
    formData.append('event_title1', event_title1);
    formData.append('event_title2', event_title2);
    formData.append('event_sub_title1', event_sub_title1);
    formData.append('event_sub_title2', event_sub_title2);
    formData.append('event1_logo', event1logo);
    formData.append('event2_logo', event2logo);


    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    // console.log(data);
  } catch (error) {
  // console.log(error);
  }
};
export {addschedule};
