

// Get Schedule List

const getschedulelist = async () => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const user = window.sessionStorage.getItem("event_key"); 
    const event_key = user; 
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/get_schedule_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ api_key: apiKey,event_key })
      });

      const data = await response.json();
      return data.schedule_list || [];
    } catch (error) {
      return [];
    }
  };  

// DELETE Event Schedule
const deleteschedule = async (ScheduleId) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/delete_schedule';
    const confirmed = await new Promise((resolve, reject) => {
              $.confirm({
                title: 'Confirm!',
                autoClose: "cancel|10000",
                content: 'Are you sure you want to delete this Event Schedule!',
                type:"red",
                closeIcon: true,
                buttons: {
                  confirm: {
                    text: 'Confirm', 
                    btnClass: 'btn-red',
                    action: resolve,
                  },
                  cancel: function () {
                  },
                },
              });
            });
    if (confirmed) {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: ScheduleId, api_key: apiKey })
    });

    const data = await response.json();

    if (data.error) {
      const error = data.message || 'Delete failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  }} catch (error) {
    return { success: false, error: 'Delete failed' };
  }
};



const getscheduledata = async (ids) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiUrl = process.env.REACT_APP_API_ENDPOINT + '/get_schedule_data';


  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: ids, api_key: apiKey })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch');
    }

    const data = await response.json();
    const {id,event1_logo,event2_logo,event_title,event_subtitle,event_date,event_place,event_time,event_duration,event_title1,event_title2,event_sub_title1,event_sub_title2
 } = data;

    return {id,event1_logo,event2_logo,event_title,event_subtitle,event_date,event_place,event_time,event_duration,event_title1,event_title2,event_sub_title1,event_sub_title2};
  } catch (error) {
    return null;
  }
};



const updateschedule = async (editData) => {
  try {
    const apiKey = process.env.REACT_APP_API_KEY;
    const url = process.env.REACT_APP_API_ENDPOINT+'/update_schedule';

    var event1_image_upload = 'no';  
    var event2_image_upload = 'no';  


    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('id', editData.id);
    formData.append('event_title', editData.event_title);
    formData.append('event_subtitle', editData.event_subtitle);
    formData.append('event_date', editData.event_date);
    formData.append('event_place', editData.event_place);
    formData.append('event_time', editData.event_time);
    formData.append('event_duration', editData.event_duration);
    formData.append('event_title1', editData.event_title1);
    formData.append('event_title2', editData.event_title2);
    formData.append('event_sub_title1', editData.event_sub_title1);
    formData.append('event_sub_title2', editData.event_sub_title2);




    const bannerimg = document.getElementById('event1_logo');
    const eventbanner = bannerimg.files[0];
    if(eventbanner){
      event1_image_upload = 'yes';
    formData.append('event1_logo', eventbanner);
    }
    formData.append('event1_image_upload', event1_image_upload);



    const bannerimg1 = document.getElementById('event2_logo');
    const eventbanner1 = bannerimg1.files[0];
    if(eventbanner1){
      event2_image_upload = 'yes';
    formData.append('event2_logo', eventbanner1);
    }
    formData.append('event2_image_upload', event2_image_upload);





    const response = await fetch(url, {
    method: 'POST',
    body: formData
    });

    const data = await response.json();


    if (data.error === true) {
      const error = data.message || 'Update failed';
      return { success: false, error };
    } else {
      return { success: true, message: data.message };
    }
  } catch (error) {
    return { success: false, error: 'Update failed' };
  }
};









export {getschedulelist,deleteschedule,getscheduledata,updateschedule};
  